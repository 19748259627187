@import "src/styles"

.container
  +fadeInAnimation(0.3s)
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0 16px
  width: 100%
  margin-bottom: 16px
  .empty
    text-align: center
    @extend %Regular-H4
    color: var(--dark-blue-gray)
    line-height: 30px