.wrapper
  color: var(--blue-2)
  margin-bottom: 16px
  .title
    font-size: 24px
    line-height: 28px
    font-weight: 400
    margin-bottom: 16px
  .container
    display: flex
    padding: 0 12px
    margin-bottom: 8px
    justify-content: space-between
    align-items: center
    .left
      display: flex
      align-items: center
      height: 100%
      .cash
        padding: 5px
        font-size: 22px
        border-radius: 100%
        margin: 12px 8px 12px 0px
        background-color: var(--gray-21)
      .info
        display: flex
        flex-direction: column
        .type
          font-weight: 400
          font-size: 16px
          line-height: 20px
          margin-bottom: 3px
        .date
          font-weight: 400
          font-size: 12px
          line-height: 16px
    .right
      font-weight: 400
      font-size: 16px
      line-height: 20px