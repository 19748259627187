@import '../../../../styles/animations'

.wrapper
  @include fadeInAnimation(0.5s)
  display: flex
  flex-direction: column
  align-items: center
  flex: 1
  padding: 0 87px
  .transactionType
    color: var(--blue-2)
    font-size: 24px
    font-weight: 700
    align-self: flex-start
    display: flex
    align-items: center
    .point
      width: 16px
      height: 16px
      background: var(--green-1)
      border-radius: 100%
      margin-right: 16px
      &.deleted
        background: var(--blue-3)
