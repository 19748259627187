.wrapper
  width: calc( 100% - 50px )
  display: flex
  align-items: center
  justify-content: space-between
  height: 40px
  padding: 0 25px
  margin: 30px 0
  .resume
    width: 280px
    height: 100%
    border-radius: 9px
    border: solid 1px var(--gray-2)
    padding: 0 15px
    display: flex
    .resumeItem
      width: 120px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      font-weight: bold
      font-size: 20px
      line-height: 18px
      color: var(--blue-2)
      &:last-child
        width: 190px
        text-align: right
        border-left: solid 1px var(--gray-2)
      .resumeTitle
        font-size: 13px
        font-weight: 300
  .searchBar
    flex: 0.9