
.wrapper
  width: calc(445px - 59px)
  height: calc(100vh - 88px)
  padding: 8px 33px
  display: flex
  flex-direction: column
  .button
    margin-bottom: 12px
    padding-top: 22px
    margin: auto 0 9px 0