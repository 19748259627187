@import '../../styles/animations'

.container
  padding: 30px
  width: calc(100% - 60px)
  height: calc(100vh - 125px)
  display: flex
  background-color: var(--gray-7)
  justify-content: space-between

.sections
  @include fadeInAnimation(0.5s)
  flex: 1
  margin-left: 30px
  padding-top: 20px
  background-color: var(--white-1)
  display: flex
  flex-direction: column
  align-items: center
