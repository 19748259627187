@import '../../../../styles/animations'

.title
  font-size: 32px
  color: var(--blue-2)
.changePassword
  color: var(--blue-2)
  width: 500px
  height: 40px
  padding: 0 15px
  border: 1px solid var(--blue-2)
  border-radius: 8px
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 15px
  align-self: flex-start
  cursor: pointer
  margin: 25px
  transition: 0.3s
  .icon
    font-size: 12px
    background-color: var(--gray-4)
    padding: 7px
    color: var(--blue-2)
    border-radius: 100%
    transition: 0.3s
  &:hover
    box-shadow: 0px 1px 15px rgba(43, 40, 69, 0.329)
    color: var(--blue-1)
    border-color: var(--blue-1)
    transform: scale(1.01)
    .icon
      background-color: var(--blue-1)
      color: var(--white-1)
      @include turnAnimation(0, 360deg)
