.wrapper
  height: 62px
  display: flex
  min-width: 440px
  width: fit-content
  align-items: center
  border-radius: 16px
  color: var(--black-2)
  background: var(--white-1)
  border: 1px solid var(--blue-2)
  .texts
    height: 100%
    display: flex
    padding: 0 20px
    flex-direction: column
    justify-content: center
    align-items: flex-start
    border-right: 1px solid var(--blue-2)
    .date
      font-size: 15px
      font-weight: 300
      line-height: 20px
    .label
      font-size: 20px
      font-weight: 700
      line-height: 20px
  .value
    display: flex
    margin: 0 0 0 auto
    align-items: center
    .number
      font-size: 40px
      line-height: 55px
      margin: 0 20px 0 30px
