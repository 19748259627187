.wrapper
  width: 100%
  height: 46px
  border: none
  display: flex
  cursor: pointer
  padding: 0 20px
  position: relative
  border-radius: 4px
  align-items: center
  box-sizing: border-box
  justify-content: center
  transition: 0.3s
  border: 1px solid transparent
  background-color: var(--green-1)
  -webkit-tap-highlight-color: transparent
  &:hover
    box-shadow: 0px 1px 15px var(--green-1)
  &:disabled
    cursor: default
    background: var(--gray-17)
    box-shadow: none
    .leftIcon
      color: var(--white-1)
    .text
      color: var(--white-1)
  &.secondary
    background-color: var(--white-1)
    border-color: var(--gray-16)
    &:hover
      box-shadow: 0px 1px 15px var(--gray-16)
    .leftIcon
      color: var(--gray-6)
    .text
      color: var(--gray-6)
    &:disabled
      cursor: default
      background: var(--gray-17)
      box-shadow: none
      .leftIcon
        color: var(--white-1)
      .text
        color: var(--white-1)
  .leftIcon
    margin-right: 8px
    font-size: 16px
    color: var(--white-1)
  .text
    font-size: 16px
    font-weight: 700
    line-height: 20px
    color: var(--white-1)
