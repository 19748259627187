.wrapper 
  .charts 
    display: flex
    flex-wrap: wrap
    .chart 
      width: 100%
      height: 200px
      margin: 22px 0 0 0
      &.middle 
        width: 50%
      
