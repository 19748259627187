@import '../../../../styles/animations'

.container
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  @include fadeInAnimation(0.3s)
  .top
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    background-color: var(--gray-3)
    padding: 24px 24px 15px 24px
    .title
      font-size: 18px
      line-height: 20px
      color: var(--blue-2)
      margin-bottom: 10px
    .item
      margin: 4px 0
      display: flex
      width: 200px
      justify-content: space-between
      .value
        line-height: 20px
        color: var(--blue-2)
      &:last-child
        .value:last-child
          font-weight: bold
  .products
    width: 100%
    margin-top: 24px
    .title
      font-size: 28px
      line-height: 28px
      color: var(--blue-2)
      letter-spacing: -0.8px
    .item
      height: 70px
      border-bottom: 0.2px solid var(--gray-2)
      display: flex
      align-items: center
      justify-content: space-between
    .left
      height: 100%
      display: flex
      justify-content: center
      flex-direction: column
      .name, .quantity
        letter-spacing: -0.4px
        color: var(--blue-2)
        margin: 3px 0
      .quantity
        color: var(--gray-6)
    .total
      letter-spacing: -0.4px
      font-weight: bold
      color: var(--blue-2)
.loading
  font-size: 20px
  margin: 30vh auto
  text-align: center
