.wrapper
  width: 300px
  display: flex
  flex-direction: column
  animation: fromLeftMenu 1s ease
  .active, .inactive
    background-color: var(--white-1)
    border-radius: 6px
    height: 48px
    width: 100%
    display: flex
    align-items: center
    margin-bottom: 10px
    font-size: 16px
    color: var(--blue-2)
    transition: 0.5s
    text-decoration: none
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.151)
  .inactive
    &:hover
      box-shadow: 0px 1px 15px rgba(43, 40, 69, 0.329)
      cursor: pointer
      color: var(--blue-1)
      transform: translateX(15px)
  .active
    transform: translateX(15px)
    background-color: var(--blue-2)
    color: var(--white-1)
    cursor: auto
  .icon
    font-size: 20px
    margin: 0 20px

@keyframes fromLeftMenu
  0%
    opacity: 0
    transform: translateX(-300px)
  60%
    transform: translateX(25px)
