.container
  position: fixed
  top: 0
  width: 100%
  height: 100vh
  background-color: var(--gray-3)
  z-index: 9999999
  display: flex
  flex-direction: column
  .icon
    animation: fromTop 0.7s ease
    width: 35px
    height: 35px
    text-align: center
    line-height: 35px
    box-shadow: 0px 6px 16px rgba(54, 54, 54, 0.3)
    border-radius: 100%
    margin: 16px
  .wrapper
    animation: fromBottom 1s ease
    width: calc( 100% - 32px )
    height: 100%
    margin: 30px auto
    padding: 0 16px
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.193)
    border-radius: 12px
    .image
      margin: 16px 0
    .title
      font-size: 32px
      letter-spacing: -1px
      color: var(--black-2)
      margin-bottom: 10px
    .description
      font-size: 20px
      line-height: 24px
      color: var(--black-2)

@keyframes fromBottom
  from
    opacity: 0
    transform: translateY(500px)
@keyframes fromTop
  from
    transform: translateY(-50px) scale(1.2)
