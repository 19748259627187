.wrapper 
  font-size: 12px
  line-height: 14px

.table 
  display: flex
  margin: 0 20px
  flex-direction: column

.tableHeader, .tableRow 
  display: grid
  grid-auto-rows: 40px
  border-bottom: 1px solid var(--gray-5)
  grid-template-columns: auto 200px 200px 200px 200px

.tableHeader 
  .cell:nth-child(n+2):nth-child(-n+5)
    justify-content: center

.tableRow 
  color: var(--gray-6)
  .cell:nth-child(n+2):nth-child(-n+4)
    justify-content: center
  .cell:nth-child(5) 
    font-weight: 700
    justify-content: center

.tableRow:hover
  color: var(--blue-1)
  
.cell 
  display: flex
  padding: 0 30px
  font-weight: 500
  text-align: center
  align-items: center
  justify-content: flex-start

.cellTitle 
  font-weight: 700
  text-align: center
