.wrapper
  width: fit-content
  height: 38px
  display: flex
  border-radius: 4px
  align-items: center
  justify-content: center
  .square
    width: 24px
    height: 24px
    display: flex
    margin: 0 4px
    cursor: pointer
    font-size: 12px
    line-height: 16px
    font-weight: bold
    border-radius: 4px
    align-items: center
    color: var(--gray-14)
    justify-content: center
    background: var(--white-1)
    border: 1px solid var(--gray-14)
    &:hover
      background-color: var(--gray-3)
    &.active
      color: var(--white-1)
      background-color: var(--purple-2)
    &.disabled
      cursor: default
      background-color: var(--gray-3)
    &.left
      .icon
        transform: rotate(180deg)
  .numbers
    display: flex