.container
  width: calc(100% - 100px )
  margin-top: 10px
  display: flex
  flex-direction: column
  align-items: center
  .wrapper
    width: 100%
    height: 40px
    border-bottom: 1px solid var(--gray-5)
    display: flex
    justify-content: space-between
    align-items: center
    .item
      color: var(--blue-2)
      font-weight: bold
      font-size: 12px
      &:last-child
        font-size: 16px