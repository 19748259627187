@import '../../../../styles/animations'

.container
  @include fadeInAnimation(0.3s)
  width: calc( 100% - 48px )
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  .title
    width: 100%
    margin: 25px 0
    font-size: 18px
    line-height: 22px
    color: var(--black-2)
  .row
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 18px
    @include fadeInAnimation(0.5s)
    .timeIcon
      font-size: 18px
    .text
      flex: 0.9
    .icon
      font-size: 12px
