.container
  width: 100%
  padding: 0 24px
  height: 72px
  display: flex
  align-items: center
  border-bottom: 0.2px solid var(--gray-13)
  .arrow
    transform: rotate(180deg)
    margin-right: 15px
  .input
    flex: 0.93
    background: var(--gray-4)
    border-radius: 18px
    height: 40px
    font-size: 16px
    padding-left: 15px
    &::placeholder
      line-height: 15px
      letter-spacing: -0.4px
      color: rgba(54, 54, 54, 0.6)
  .closeIcon
    font-size: 12px
    transform: translateX(-25px)
