.container
  width: 100%
  background-color: var(--gray-3)
  .box
    width: calc( 100% - 28px )
    padding: 0 14px
    height: 48px
    background-color: var(--white-1)
    border: 1px solid var(--gray-2)
    border-radius: 6px
    margin: 8px 0
    display: flex
    justify-content: space-between
    align-items: center
    .data
      font-size: 16px
      .title
        font-size: 14px
        opacity: 0.5
    .arrow
      font-size: 10px
      margin-right: 5px
.disabled
  .box
    background-color: var(--gray-3)
    .arrow
      display: none
