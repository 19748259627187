.wrapper
  color: var(--blue-2)
  height: 63px
  width: calc( 100% - 36px )
  padding: 0 18px
  margin-top: 16px
  border: 1px solid var(--gray-18)
  border-radius: 4px
  display: flex
  justify-content: space-between
  align-items: center
  transition: 0.5s
  &:hover
    border-color: var(--blue-1)
  .arrow,.arrow1
    font-size: 18px
    cursor: pointer
    transition: 0.5s ease
    width: 20px
    text-align: center
    &.disable
      color: var(--gray-17)
      &:hover
        transform: translateX(0)
        color: var(--gray-17)
        cursor: auto
    &:hover
      color: var(--blue-1)
      transform: translateX(5px)
  .arrow1
    transform: rotate(180deg)
    &:hover
      color: var(--blue-1)
      transform: rotate(180deg) translateX(5px)
    &.disable
      &:hover
        transform: rotate(180deg)
  .date
    font-size: 20px
    letter-spacing: -0.1px
    text-transform: capitalize
