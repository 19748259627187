@import '../../styles/breakpoints'

.wrapper
  z-index: 100
  top: 16px
  right: 16px
  height: 78px
  width: 450px
  display: flex
  cursor: pointer
  position: fixed
  align-items: center
  box-sizing: border-box
  transition: transform 1s
  border-radius: 0 6px 6px 0
  background-color: var(--black-3)
  border-left: 8px solid var(--blue-1)
  z-index: 99999999
  &.show
    transform: translateX(0%)
  &.hide
    transition: transform 0.7s ease-in-out
    transform: translatex(#{calc(100% + 16px)})
  .check, .close
    width: 26px
    height: 26px
    display: flex
    flex: 0 0 auto
    border-radius: 50%
    align-items: center
    justify-content: center
    margin: 26px 14px 26px 20px
  .check
    background-color: var(--blue-1)
    .icon
      font-size: 14px
      color: var(--white-1)
  &.error
    border-left-color: var(--red-1)
    .check
      background-color: var(--red-1)
  &.success
    border-left-color: var(--green-1)
    .check
      background-color: var(--green-1)
  .close
    margin: 26px 14px 26px auto
    background-color: var(--white-1)
    .icon
      font-size: 14px
  .text
    font-size: 18px
    font-weight: 700
    line-height: 20px
    color: var(--white-1)
    margin: 26px 16px 26px 0

+for-phone-only
  .wrapper
    $margin: 16px
    border: none
    border-radius: 18px
    width: calc(100vw - #{$margin} - #{$margin})
    background-color: var(--blue-2)
    justify-content: center
    &.error
      background-color: var(--red-2)
      .text
        color: var(--white-1)
    &.show
      transform: translateY(0%)
    &.hide
      transform: translateY(#{calc(-100% - 16px)})
    .check, .close
      display: none
    .text
      margin: 16px
      font-size: 16px
      line-height: 16px
      text-align: center
      font-weight: normal
