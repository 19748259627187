@import '../../../../styles/animations'
@import '../../../../styles/breakpoints'

.wrapper
  display: flex
  flex-direction: column
  box-sizing: border-box
  justify-content: center
  align-items: center
  z-index: 10
  .logoWrapper
    margin-bottom: 16px
    margin-top: 65px
    display: flex
    justify-content: center
    z-index: 11
    @include fadeInAnimation(1s)
    .logo
      width: 121px
      height: 62.4px
  .images
    z-index: 5
    .computer
      position: fixed
      bottom: 0
      right: 0
      height: calc( 100% / 1.9 )
      @include fromBottomAnimation(1s, 220px)
      z-index: 5
    .rightDots
      position: fixed
      top: 103px
      bottom: 0
      right: 13px
      width: 188px
      height: 251px
      z-index: 4
    .elipse
      position: fixed
      left: 0
      right: 0
      top: 0
      bottom: 0
      width: 100%
      height: 100%
      z-index: 3
      opacity: 50%
    .leftDots
      position: fixed
      top: 7px
      left: 10%
      width: 201px
      height: 257px
      z-index: 4

@include for-phone-only
  .wrapper
    .logoWrapper
      .logo
        margin-bottom: 48px
    .images
      .computer
        display: none
      .rightDots
        display: none
      .leftDots
        position: absolute
        left: 0
        top: auto
        bottom: 0

.loaderWrapper
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  .loader
    height: 500px

