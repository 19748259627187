.wrapper
  display: flex
  flex-direction: column
  padding: 10px
  .title
    font-size: 32px
    color: var(--blue-2)
    text-align: center
  .inputs
    display: flex
    flex-direction: column
    row-gap: 15px
    margin: 20px 0
  .range
    display: flex
    column-gap: 10px
