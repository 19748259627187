@import "src/styles"

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  min-width: 314px
  width: 30%
  max-width: 400px
  .title
    padding-bottom: 16px
    display: flex
    width: 100%
    align-self: flex-start
    border-bottom: 1px solid var(--gray-60)
    .icon
      width: 42px
      height: 42px
      display: flex
      align-items: center
      justify-content: center
      border-radius: 8px
      background-color: var(--bright-yellow)
  .texts
    color: var(--yankees-blue)
    margin-left: 16px
    .top
      @extend %Regular-Body
    .bottom
      @extend %Bold-Subtitle
  .list
    width: 100%
    // height: calc( 100vh - 325px )
    height: calc( 100vh - 275px )
    overflow: hidden
  .loading
    width: 100%
    box-sizing: border-box
    display: grid
    row-gap: 8px
  .paginator
    margin-top: 24px
    button
      &:first-child:not(:disabled), &:last-child:not(:disabled)
        border-color: var(--gray-25)
        &:hover
          border-color: var(--green)
  .falsePickers
    height: 16px