.container
  display: flex
  flex-direction: column
  width: calc(100% - 126px)
  height: calc( 100vh - 135px )
  overflow: hidden
  padding: 27px 63px 40px 63px
  margin-top: 0
  color: var(--blue-2)
  .text
    font-size: 18px
    letter-spacing: -0.1px
  .devolution
    width: 100%
    display: flex
    padding-top: 23px
    height: 100%
    overflow: hidden
    .divisor
      border-left: 1px solid var(--gray-17)
      height: 100%
  .footer
    margin-top: 53px
    align-self: flex-end
    width: 344px
