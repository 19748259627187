@import "src/styles"

.wrapper
  +fadeInAnimation(0.3s)
  display: flex
  flex-direction: column
  overflow-y: auto
  padding: 0 16px
  width: 100%
  .title
    font-size: 18px
    font-weight: 700
    line-height: 22px
    letter-spacing: -0.1px
