@import '../../../../styles/animations'
@import '../../../../styles/mixin'

.table
  margin: 24px 0
  width: 100%
  padding: 0 20px
  @include fadeInAnimation(0.5s)
  .row, .rowHeader
    height: 40px
    text-align: left
  .row
    &:hover
      transform: scale(1.001)
      box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.25)
      .cell
        border-color: var(--blue-1)
  .header, .cell
    font-size: 12px
    line-height: 16px
    color: var(--blue-2)
    border-bottom: 1px solid var(--gray-5)
    background-color: var(--white-1)
    transition: 0.3s
  .header
    font-weight: bold
    .headerWrapper
      display: flex
      align-items: center
      text-align: center
      cursor: pointer
      .title
        margin: 0 4px 0 4px
      .icon
        margin-top: 4px
    &.active
      background-color: var(--green-10)
      .headerWrapper
        .icon
          +svgColor(var(--green))
    &.buttons
      .headerWrapper
        justify-content: center
        .title
          cursor: default
          justify-content: center
  .cell
    font-size: 14px
    line-height: 20px
    color: var(--gray-6)
    width: 120px
    &:nth-child(n+5)
      width: 90px
    .nameCell
      display: flex
      align-items: center
      width: 200px
      cursor: pointer
      .icon
        margin-right: 15px
        color: var(--blue-2)
        transition: 0.5s
        font-size: 23px
      &:hover
        color: var(--blue-1)
        .icon
          margin-left: 25px
          @include turnAnimation(90deg, 360deg)
  .switchWrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: center
.loading
  font-size: 20px
  color: var(--gray-6)
  margin-top: 70px
