.wrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 24px
  margin-left: 16px
  padding-bottom: 16px
  .element-1
    width: calc( 75% - 16px )
    height: 22px
    border-radius: 8px
  .element-2
    width: calc( 100% - 16px )
    border-radius: 8px
    height: 48px
  .element-3
    width: calc( 100% - 16px )
    height: 141px
    border-radius: 8px
  .element-4, .element-5
    width: calc( (100% / 2) - 28px)
    border-radius: 8px
    height: 109px
    margin-right: 16px
  .element-6, .element-7
    width: calc( (100% / 2) - 28px)
    border-radius: 8px
    height: 109px
    margin-right: 16px
  .element-8, .element-18, .element-22
    width: calc( 100% + 16px )
    height: 1px
    margin-left: -16px
  .element-9, .element-19, .element-23
    width: calc( 70% - 16px )
    height: 24px
    border-radius: 8px
  .element-10, .element-11, .element-12, .element-13, .element-14, .element-15, .element-16, .element-17, .element-20, .element-21
    width: calc( (100% / 2) - 16px )
    border-radius: 8px
    height: 24px
  .element-11, .element-13, .element-15, .element-17, .element-21
    width: 35%
    margin-right: 16px
  .element-24
    width: calc( 60% - 16px )
    height: 24px
    border-radius: 8px
  .element-25
    width: calc( 100% - 16px )
    height: 48px
    border-radius: 8px