@import "src/styles"

.wrapper
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  row-gap: 8px
  overflow-y: auto
  @include fadeInAnimation(0.3s)
  .closure
    height: 40px
    min-height: 40px
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 16px
    width: 100%
    text-transform: capitalize
    @extend %Regular-Medium-Body
    color: var(--yankees-blue)
    cursor: pointer
    box-sizing: border-box
    border: 1px solid transparent
    border-radius: 4px
    .right
      display: flex
      align-items: center
      column-gap: 16px
      color: var(--dark-blue-gray)
    &.active
      background-color: var(--green-10)
    &:hover:not(.active)
      border-color: var(--green)
