@import '../../../../styles/animations'

.wrapper
  display: flex
  flex-direction: column
  min-height: calc(100vh - 60px)
  background-color: var(--gray-3)
  .dashboardItems
    display: flex
    margin: 40px 0
    column-gap: 20px
    align-items: center
    justify-content: center
    .item
      width: 160px
      height: 160px
      display: flex
      cursor: pointer
      align-items: center
      border-radius: 16px
      color: var(--blue-1)
      flex-direction: column
      justify-content: center
      background: var(--white-1)
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
      transition: 0.3s
      .icon
        font-size: 56px
        text-align: center
        margin-bottom: 12px
      .name
        font-size: 24px
        font-weight: 300
        line-height: 30px
      &:hover
        box-shadow: 0px 0px 15px rgba(26, 120, 228, 0.25)
  .todayNumbers
    display: flex
    column-gap: 20px
    margin: 0 0 20px 0
    align-items: center
    justify-content: center
    @include fadeInAnimation(0.5s)
    @include animationDelay(0.5s)
  .topProductSales
    display: flex
    margin: 0 20px
    column-gap: 20px
    @include fadeInAnimation(0.5s)
    @include animationDelay(0.7s)
  .item
    @include fromTopAnimation(0.3s, 50px)
    animation-fill-mode: both
    @for $i from 1 through 7
      &:nth-child(#{$i})
        animation-delay: $i * 100ms
