@import 'src/styles'

.box
  display: flex
  width: calc( 100% / 2 )
  padding: 16px 16px
  flex-direction: column
  column-gap: 1px
  border-radius: 8px
  border: 1px solid var(--gray-16)
  background: var(--white)
  .title
    font-size: 16px
    line-height: 20px
    margin: 2px 0 2px 0
  .value
    font-size: 20px
    font-weight: 700
    line-height: 24px
    letter-spacing: -0.1px