@import  '../../../../styles/animations'

.container
  @include fadeInAnimation(0.5s)
  width: 100%
  padding-top: 16px
  background-color: var(--gray-3)
  .wrapper
    width: calc( 100% - 32px )
    padding: 16px
    background-color: var(--white-1)
    border-radius: 10px 10px 0 0
    display: flex
    flex-direction: column
    row-gap: 20px
    .name
      font-size: 16px
      text-align: center
      margin: 10px 0