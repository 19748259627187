.wrapper
  display: flex
  padding: 20px
  column-gap: 14px
  .resume
    flex: 1
    padding: 14px
    .title
      font-size: 24px
      font-weight: 700
      line-height: 33px
      margin: 0 0 20px 0
      color: var(--black-2)
    .items
      display: grid
      grid-row-gap: 12px
      grid-template-columns: 1fr 1fr
      .label, .value
        font-size: 16px
        font-weight: 300
        line-height: 22px
        color: var(--black-2)
      .value
        text-align: end
  .right
    width: 450px
    .totals, .provider
      grid-column: 2
      padding: 20px
      display: grid
      row-gap: 8px
      grid-template-columns: 1fr 1fr
      .label, .value
        font-size: 16px
        font-weight: 300
        line-height: 22px
        color: var(--black-2)
      .value
        text-align: end
      .strong
        font-size: 18px
        font-weight: 700
        line-height: 25px
      .payBox
        height: 40px
        display: flex
        padding: 0 14px
        border-radius: 6px
        grid-column: span 2
        align-items: center
        justify-content: space-between
        background-color: var(--orange-1)
        .text
          font-size: 14px
          font-weight: 700
          line-height: 19px
          color: var(--white-1)
        .havePaid
          height: 28px
          display: flex
          cursor: pointer
          padding: 0 14px
          font-size: 12px
          font-weight: 700
          line-height: 16px
          border-radius: 6px
          align-items: center
          color: var(--orange-1)
          justify-content: center
          background-color: var(--white-1)
    .divider
      width: 100%
      height: 1px
      margin: 2px 0 4px 0
      grid-column: span 2
      background-color: var(--gray-2)