.wrapper
  width: calc((100% / 2) - 90px )
  padding: 0 90px 0 0
  overflow-y: auto
  display: flex
  flex-direction: column
  &.right
    padding: 0 0 0 90px
    .title
      padding-left: 0
  .title
    padding-left: 16px
    font-weight: 700
    font-size: 18px
    letter-spacing: -0.1px
.emptyState
  margin: auto
