.wrapper
  width: 300px
  padding: 25px
  height: 390px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.137)
  border-radius: 10px
  .title
    width: 100%
    font-size: 28px
    letter-spacing: -0.8px
    text-align: center
    color: var(--blue-2)
    padding-bottom: 15px
    border-bottom: 1px solid var(--gray-13)
  .inputs
    width: 100%
    height: 125px
    display: flex
    flex-direction: column
    row-gap: 15px
  .switchWrapper
    display: flex
    font-size: 16px
    column-gap: 15px
    color: var(--blue-2)
