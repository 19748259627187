@import '../../styles/animations'

.container
  height: 48px
  display: flex
  justify-content: center
  min-width: 200px
  position: relative
  border-radius: 8px
  align-items: center
  box-sizing: border-box
  border: 1px solid var(--gray-2)
  &:focus-within
    border: 1px solid var(--blue-2)
  &.error
    border: 1px solid var(--red-1)
    .icon, .label
      color: var(--red-1)
    .errorMessage
      opacity: 1
      @include fromRightTextAnimation(0.5s)
  .label
    cursor: pointer
    top: 5px
    left: 16px
    font-size: 10px
    line-height: 14px
    position: absolute
    color: var(--gray-2)
    @include fromRightTextAnimation(0.3s)
  .text
    flex: 1
    height: 100%
    display: flex
    cursor: pointer
    align-items: center
    padding: 16px 0 0 16px
    transition: 0.5s
    &.placeholder
      padding: 0 0 0 16px
      color: var(--gray-2)
  .icon
    cursor: pointer
    padding: 0 16px 0 0
  .pickerDates
    z-index: 20
    top: calc(100% + 2px)
    width: calc( 100% - 44px)
    min-width: 260px
    position: absolute
    border-radius: 8px
    background-color: var(--white-1)
    box-shadow: 0px 6px 10px rgba(128, 98, 96, 0.16)
    padding: 16px 24px
    .header
      display: flex
      align-items: center
      color: var(--black-4)
      .arrowContent
        width: 28px
        height: 28px
        display: flex
        cursor: pointer
        border-radius: 50%
        align-items: center
        justify-content: center
        &:hover
          background-color: var(--gray-2)
        &.left
          transform: rotate(180deg)
        .icon
          margin: 0
          padding: 0
          font-size: 10px
      .disabledIcon
        width: 28px
        height: 28px
        display: flex
        cursor: pointer
        border-radius: 50%
        align-items: center
        justify-content: center
        &.left
          transform: rotate(180deg)
        .icon
          margin: 0
          padding: 0
          font-size: 10px
          cursor: default
          color: var(--gray-2)
          background-color: 0
      .text
        padding: 0
        display: flex
        cursor: pointer
        align-items: center
        justify-content: center
        .icon
          padding: 0
          font-size: 10px
        .month
          font-size: 16px
          font-weight: 400
          line-height: 24px
          margin: 0 6px 0 0
          text-transform: capitalize
        .year
          font-size: 16px
          font-weight: 700
          line-height: 24px
          margin: 0 6px 0 0
        .icon
          padding: 0
          margin: 2px 0 0 0
    .daysSelector
      padding: 16px 0 0 0
      display: grid
      @include dropDownAnimation(0.3s, 300px)
      grid-auto-rows: 36px
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr
      .cell
        display: flex
        align-items: center
        justify-content: center
        &.active
          .day
            color: var(--white-1)
            background-color: var(--purple-2)
        &.dayName
          color: var(--black-4)
          font-size: 12px
        &.isDisabled
          .day
            cursor: default
            color: var(--gray-2)
            &:hover
              background-color: transparent
        .day
          width: 28px
          height: 28px
          display: flex
          cursor: pointer
          border-radius: 50%
          align-items: center
          justify-content: center
          color: var(--gray-6)
          &:hover
            background-color: var(--gray-2)
          &.disabled
            cursor: default
            color: var(--gray-2)
            &:hover
              background-color: var(--white-1)
    .yearsSelector
      top: 50px
      left: 0
      width: 100%
      overflow-y: scroll
      overflow-x: hidden
      position: absolute
      border-radius: 8px
      height: calc(48px * 4)
      background-color: var(--white-1)
      box-shadow: 0px 6px 10px rgba(128, 98, 96, 0.16)
      @include dropDownAnimation(0.3s,  calc(48px * 4))
      .year
        width: 100%
        height: 48px
        display: flex
        cursor: pointer
        align-items: center
        padding: 0 0 0 16px
        &:hover
          background-color: var(--gray-2)
  .errorMessage
    opacity: 0
    font-size: 10px
    line-height: 12px
    position: absolute
    color: var(--red-1)
    top: calc(100% + 2px)
    left: 10px
    transition: opacity .3s
