.wrapper
  height: 30px
  width: fit-content
  display: flex
  border-radius: 30px
  background-color: var(--gray-7)
  cursor: pointer
  .active, .inactive
    transition: none
    height: 100%
    width: 194px
    display: flex
    font-size: 18px
    font-weight: bold
    align-items: center
    justify-content: center
    text-decoration: none
  .inactive
    color: var(--black-1)
  .active
    border-radius: 30px
    color: var(--blue-1)
    box-sizing: border-box
    border: 2px solid var(--blue-1)
    background-color: var(--white-1)
.title
  font-size: 28px
  font-weight: bold
  margin-bottom: 20px
  color: var(--blue-2)
