@import '../../styles/animations'

.wrapper
  width: 100%
  display: flex
  flex-direction: column
  .top
    display: flex
    height: 88px
    justify-content: center
    border-radius: 8px
    box-sizing: border-box
    border: 1px solid var(--gray-20)
    flex-direction: column
    &:focus-within
      border: 1px solid var(--blue-2)
      .label
        display: block
    &.error
      border: 1px solid var(--red-1)
    .label
      margin: 8px 0 0 16px
      height: 14px
      font-size: 10px
      line-height: 14px
      color: var(--gray-6)
      display: none
      @include fromRightTextAnimation(0.3s)
    .textarea
      padding: 0 16px
      height: calc(100% - 16px)
      font-size: 16px
      line-height: 20px
      background-color: transparent
      border: none
      overflow: auto
      outline: none
      -webkit-box-shadow: none
      -moz-box-shadow: none
      box-shadow: none
      resize: none
      text-align: left
      &::placeholder
        color: var(--gray-20)
  .bottom
    padding: 8px 16px
    font-size: 12px
    line-height: 16px
    color: var(--gray-20)
    &.error
      color: var(--red-1)
