.wrapper
  width: 605px
  height: 478px
  border-radius: 10px
  border: 1px solid var(--gray-14)
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: center
  .title
    width: calc( 100% - 25px )
    padding: 20px 0 10px 0
    text-align: center
    font-size: 25px
    font-weight: bold
    color: var(--blue-2)
    border-bottom: 1px solid var(--gray-13)
  .table
    width: 100%
    .head, .cell
      height: 40px
      font-size: 12px
      text-align: center
      color: var(--blue-2)
      border-bottom: 1px solid var(--gray-5)
      background-color: var(--white-1)
      &:first-child
        text-align: left
        padding-left: 10px
      &:last-child
        padding-right: 10px
    .cell
      font-size: 16px
      line-height: 20px
      transition: 0.3s
      color: var(--gray-6)
    .checkIcon, .closeIcon
      background-color: var(--green-1)
      border-radius: 20px
      font-size: 12px
      color: var(--white-1)
      padding: 6px
    .closeIcon
      font-size: 10px
      background-color: var(--red-1)
    .state
      background-color: var(--green-1)
      border-radius: 20px
      font-size: 12px
      font-weight: bold
      color: var(--white-1)
      text-align: center
      &.inactive
        background-color: var(--gray-2)
        color: var(--gray-5)
    .row
      transition: 0.3s
      cursor: pointer
      &:hover
        transform: scale(1.001)
        box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.25)
        .cell
          border-color: var(--blue-1)
  .empty
    margin: auto 0
    font-size: 20px
    color: var(--blue-2)
