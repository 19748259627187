@import '../../styles/animations'

.wrapper
  width: 100%
  height: 48px
  display: flex
  position: relative
  border-radius: 8px
  align-items: center
  box-sizing: border-box
  border: 1px solid var(--gray-2)
  &.disabled
    background-color: var(--gray-3)
    border-color: var(--gray-5)
  &:focus-within
    border: 1px solid var(--blue-2)
  &.error
    border: 1px solid var(--red-1)
    .icon
      color: var(--red-1)
    .errorMessage
      opacity: 1
      @include fromRightTextAnimation(0.5s)
  .left
    flex: 1
    height: 100%
    display: flex
    margin: 0 16px
    flex-direction: column
    justify-content: center
    .label
      font-size: 10px
      line-height: 14px
      color: var(--gray-2)
      @include fromRightTextAnimation(0.3s)
    .inputWrapper
      display: flex
      &.show
        .input
          text-align: right
        .sign
          transition: 0.5s
          display: inline
          animation: bottom 0.5s ease
          @keyframes bottom
            from
              opacity: 0
              transform: translateY(15px)
      .sign
        display: none
        color: var(--blue-2)
      .input
        width: 100%
        font-size: 16px
        line-height: 20px
        color: var(--black-2)
        background-color: transparent
        transition: 1s
        text-align: left
  .icon
    width: 40px
    height: 100%
    display: flex
    font-size: 12px
    align-items: center
    justify-content: center
  .errorMessage
    opacity: 0
    font-size: 10px
    line-height: 12px
    position: absolute
    color: var(--red-1)
    top: calc(100% + 2px)
    left: 10px
    transition: opacity .3s
