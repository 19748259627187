@import '../../../../styles/animations'
@import '../../../../styles/mixin'

.wrapper
  padding: 20px
  .headerFilters
    display: flex
    column-gap: 10px
    margin: 0 0 30px 0
    .resume
      height: 40px
      display: flex
      padding: 0 16px
      border-radius: 8px
      flex-direction: column
      align-items: flex-start
      justify-content: center
      border: 1px solid var(--black-2)
      color: var(--blue-2)
      .resumeTitle
        font-weight: 300
        font-size: 13px
        line-height: 16px
      .resumeTotal
        font-weight: 700
        font-size: 20px
        line-height: 18px
    .searchBar
      width: 650px
      flex: 0.99
      margin: 0 auto
    .selects
      width: 400px
      display: flex
      column-gap: 10px
  .table
    width: calc(100% - 60px)
    padding: 0 25px
    margin-bottom: 20px
    @include fadeInAnimation(0.5s)
  .header, .body
    width: 100%
    display: grid
    grid-template-columns: repeat(6, 1fr)
    cursor: pointer
    .cell
      height: 40px
      border-bottom: 1px solid var(--gray-5)
      background-color: var(--white-1)
      display: flex
      align-items: center
      justify-content: center
      &:nth-child(1),  &:nth-child(2) , &:nth-child(3)
      justify-content: flex-start
  .header
    .cell
      color: var(--blue-2)
      font-weight: bold
      font-size: 12px
      margin: 0 12px 0 -4px
      padding-left: 4px
      align-items: center
      .title
        margin-right: 4px
      .icon
        margin-top: 4px
      &.active
        background-color: var(--green-10)
        .icon
          +svgColor(var(--green))
  .body
    .cell
      font-size: 14px
      transition: 0.3s
      color: var(--gray-6)
      word-break: break-all
      overflow: hidden
      .checkIcon, .closeIcon
        background-color: var(--green-1)
        border-radius: 20px
        font-size: 9px
        color: var(--white-1)
        padding: 6px
      .closeIcon
        background-color: var(--red-1)
    &:hover
      transform: scale(1.001)
      box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.25)
      .cell
        border-color: var(--blue-1)
  .paginator
    display: flex
    justify-content: center
    .empty
      margin-top: 100px
      font-size: 25px
      color: var(--blue-2)
.loading
  display: flex
  justify-content: center
  margin-top: 100px
  font-size: 25px
  color: var(--blue-2)
