.wrapper
  width: calc( 100% )
  display: flex
  flex-direction: column
  align-items: center
  color: var(--blue-2)
  .top
    padding-left: 20px
    width: calc( 100% - 20px )
    height: 80px
    display: flex
    justify-content: space-between
    border-block: 1px solid var(--gray-19)
    font-size: 18px
    .quantity
      margin-top: 6px
      .length
        font-weight: 700
        width: 20px
        margin-right: 26px
        margin-top: 8px
    .subtotal
      height: 100%
      display: grid
      grid-template-columns: 1fr 145px
      align-items: center
      text-align: end
      .value
        font-size: 16px
  .total
    align-self: flex-end
    font-size: 20px
    display: flex
    text-align: end
    margin-top: 16px
    .value
      width: 145px
      font-size: 18px
      font-weight: 700
