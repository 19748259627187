@import "src/styles"

.wrapper
  display: flex
  column-gap: 24px
  +fadeInAnimation(0.5s)
  justify-content: space-between
  .balance
    width: calc( 100% * 2 / 3 )
    min-height: 230px
    background-color: var(--yankees-blue)
    border-radius: 8px
    box-sizing: border-box
    padding: 24px 32px
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-end
    position: relative
    overflow: hidden
    .title
      font-size: 40px
      letter-spacing: -1px
      color: var(--white)
      z-index: 999
    .total
      @extend %Regular-Body
      color: var(--white)
      z-index: 999
      text-align: right
      .value
        font-size: 53px
        letter-spacing: -1px
        font-weight: 700
        line-height: 63px
        z-index: 999
    .icon
      position: absolute
      transform: matrix(0.93, -0.36, 0.36, 0.93, 0, 0)
      top: 55px
      left: -16px
      z-index: 0
  .box
    width: calc( 100% / 3 )
    min-height: 230px
    row-gap: 15px
    .boxItem
      display: flex
      column-gap: 16px
      align-items: center
      color: var(--yankees-blue)
      .boxIcon
        background-color: var(--gray-5)
        padding: 11px
        border-radius: 4px
        min-width: 24px
      .user
        @extend %Regular-Body
        overflow: hidden
        .name
          @extend %Bold-Body
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
      .download
        @extend %Bold-H4

@media (max-width: 1200px)
  .wrapper
    .balance
      .icon
        display: none
