@import '../../../../styles/animations'

.wrapper
  width: 371px
  height: 478px
  border-radius: 10px
  display: flex
  background-color: var(--gray-3)
  flex-direction: column
  align-items: center
  margin: 0 20px
  position: relative
  .icon
    position: absolute
    top: 14px
    background-color: var(--gray-13)
    padding: 10px
    font-size: 20px
    border-radius: 50px
    transform: rotate(180deg)
    cursor: pointer
    transition: 0.3s
    @include turnAnimation(360deg, 180deg)
    &:hover
      color: var(--blue-1)
      box-shadow: 0px -3px 19px -6px rgba(38, 17, 228, 0.836)
  .backIcon
    left: 14px
  .closeIcon
    right: 14px
    animation: step 0.7s ease-in
  .title
    width: calc( 100% - 25px )
    font-weight: bold
    font-size: 25px
    text-align: center
    color: var(--blue-2)
    border-bottom: 1px solid var(--gray-13)
    padding: 20px 0 10px 0
  .desactivation
    width: calc( 100% - 60px )
    position: absolute
    top: 100px
  .inputsWrapper
    width: calc( 100% - 60px )
    height: 272px
    margin-top: 124px
    display: flex
    flex-direction: column
    justify-content: space-between
    .inputs
      width: 100%
      display: flex
      flex-direction: column
      row-gap: 18px
      .input
        background-color: var(--white-1)
    .recurrency
      text-align: center
      font-size: 16px
      color: var(--blue-2)
    .daysWrapper
      display: flex
      width: 100%
      justify-content: space-between
      margin-top: 10px
      .day
        display: flex
        align-items: center
        justify-content: center
        width: 30px
        height: 30px
        font-weight: bold
        font-size: 14px
        background-color: var(--white-1)
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)
        color: var(--blue-2)
        border-radius: 50px
        cursor: pointer
        transition: 0.3s
        @include stepAnimation()
        &:hover
          transform: scale(1.2)
          color: var(--blue-1)
        &.active
          background-color: var(--blue-2)
          color: var(--white-1)
          &:hover
            background-color: var(--blue-1)
            