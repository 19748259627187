.wrapper
  width: calc( 100% - 60px )
  display: flex
  flex-direction: column
  align-items: center
  .title
    color: var(--blue-2)
  .description
    color: var(--blue-2)
    font-size: 16px
    margin: 20px 0 30px 0
  .inputs
    width: 100%
    margin-bottom: 45px
    row-gap: 15px
    display: flex
    flex-direction: column

