@import 'src/styles'
    
.wrapper
  height: 100%
  width: calc( 100% - 16px )
  display: flex
  flex-direction: column
  row-gap: 8px
  overflow-y: auto
  margin-top: 16px
  @include fadeInAnimation(0.3s)
  @include animationDelay(500ms)
  .closure
    height: 40px
    min-height: 40px
    display: flex
    justify-content: space-between
    align-items: center
    text-transform: capitalize
    @extend %Regular-Subtitle
    color: var(--yankees-blue)
    cursor: pointer
    .right
      display: flex
      align-items: center
      column-gap: 16px
      color: var(--yankees-blue)