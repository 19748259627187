@import '../../../../styles/animations'

.wrapper
  width: 100%
  display: flex
  flex-flow: row wrap
  justify-content: space-between
  position: relative
  padding-right: 4px
  .searchbar
    width: 299px
    height: 48px
