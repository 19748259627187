.wrapper
  .charts 
    display: flex
    flex-wrap: wrap
    .chart 
      margin: 22px 0 0 0
      height: 200px
      width: 100%
      &.middle 
       width: 50%
