.wrapper
  display: flex
  flex-direction: column
  height: 90px
  margin: 18px 22px 0
  text-align: center
  font-size: 18px
  word-wrap: break-word
  .text
    color: var(--blue-2)
    &.focus
      font-weight: 700
      color: var(--green-1)
