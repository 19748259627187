@import '../../../../styles/animations'

.wrapper
  @include fadeInAnimation(0.5s)
  display: flex
  flex-direction: column
  overflow-x: hidden
  width: 100%
  margin-top: 16px
  padding-right: 4px
  transition: 0.3s