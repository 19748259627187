.wrapper
  width: 900px
  display: flex
  flex-direction: column
  align-items: center
  .total
    color: var(--blue-1)
    font-size: 23px
    position: absolute
    left: 20px
  .title
    font-weight: bold
    font-size: 38px
    color: var(--black-2)
    margin-top: 20px
  .tableWrapper
    width: 100%
    height: 45vh
    margin: 20px 0
    overflow: scroll
  .table
    width: 100%
    .head, .cell
      height: 35px
      font-size: 15px
      text-align: center
      color: var(--blue-2)
      border-bottom: 1px solid var(--gray-6)
      background-color: var(--white-1)
    .cell
      font-size: 15px
      line-height: 20px
      transition: 0.3s
      color: var(--gray-6)
      &:nth-child(2)
        max-width: 200px
    .row
      transition: 0.3s
      &:hover
        .cell
          border-color: var(--blue-1)
          color: var(--blue-1)
  .buttons
    margin-top: 20px
    display: flex
    column-gap: 10px
