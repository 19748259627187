@import '../../../../styles/variables'
  
.wrapper
  width: 400px
  height: 390px
  padding: 25px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  background: var(--gray-3)
  border-radius: 10px
  .title
    width: 100%
    font-size: 28px
    letter-spacing: -0.8px
    text-align: center
    color: var(--blue-2)
    padding-bottom: 15px
    border-bottom: 1px solid var(--gray-13)
  .tableWrapper
    width: 100%
    height: 210px
    overflow-y: scroll
    .table
      width: 100%
      .row
        height: 35px
      .header, .cell
        width: calc(100% / 3)
        font-size: 12px
        text-align: center
        border-bottom: 1px solid var(--blue-2)
      .header
        font-weight: bold
        &:nth-child(2)
          background-color: var(--gray-13)
      .cell:nth-child(2)
        background-color: var(--gray-5)
