@import "src/styles"

.wrapper
  display: flex
  width: 100%
  justify-content: space-between
  color: var(--yankees-blue)
  +fadeInAnimation(0.5s)
  column-gap: 60px
  .box
    width: calc( 100% / 4 )
    padding: 16px 24px
    height: auto
    display: flex
    justify-content: center
    .title
      @extend %Regular-Body
      margin-top: 4px
    .total
      @extend %Bold-H3

@media (max-width: 1300px)
  .wrapper
    column-gap: 16px
