@import '../../../../styles/animations'

.wrapper
  display: flex
  flex-direction: column
  background-color: var(--gray-3)
  @include fadeInAnimation(0.3s)
  .success
    display: flex
    padding: 0 20px
    flex-direction: column
    justify-content: space-between
    height: calc(100vh - 150px)
    background-color: var(--white-1)
    border-radius: 20px 20px 0 0
    margin-top: 88px
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.082)
    animation: fromBottom 0.7s ease-in-out
    .img
      width: 90px
      margin: 16px 0
    .title
      font-size: 32px
      font-weight: 600
      line-height: 36px
      margin: 0 0 16px 0
      letter-spacing: -1px
    .description
      font-size: 20px
      line-height: 24px
      color: var(--blue-2)
    .button
      height: 72px
      display: flex
      align-items: center
      justify-content: center
  .header
    height: 64px
    display: flex
    margin: 0 16px
    font-size: 16px
    font-weight: bold
    line-height: 14px
    align-items: center
    background-color: var(--white-1)
  .form
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-between
    height: calc(100vh - 120px)
    .description
      text-align: center
      color: var(--gray-6)
      font-size: 16px
      line-height: 20px
      margin: 24px 33px 0 33px
    .inputs
      row-gap: 15px
      display: flex
      flex-direction: column
      width: calc(100% - 40px)
      padding-bottom: 64px
    .footer
      width: 100%
      height: 72px
      display: flex
      align-items: center
      background: var(--white-1)
      justify-content: center
      box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12)
      .button
        width: calc(100% - 40px)

@keyframes fromBottom
  from
    transform: translateY(100vh)
    opacity: 0