@import '../../../../styles/animations'

.calendar
  width: 62px
  border: 1px solid var(--gray-18)
  height: 46px
  border-radius: 4px
  display: flex
  cursor: pointer
  transition: 0.3s
  .icon
    color: var(--gray-6)
    font-size: 33px
    margin: auto
    transition: 0.3s
  &:hover
    border-color: var(--blue-1)
    .icon
      color: var(--blue-1)
.datePicker
  z-index: 10
  position: absolute
  left: 396px
  top: -3px
    
