@import '../../../../styles/animations'

.container
  flex: 1
.empty
  margin: auto
  .title
    font-size: 24px
    color: var(--blue-2)
    margin-bottom: 64px
    @include fadeInAnimation(0.5s)
  .img
    width: 270px
    height: 279px
    margin: 0 auto
    @include fadeInAnimation(1s)
    
