@import '../../../../styles/animations'
@import '../../../../styles/breakpoints'

.wrapper
  display: flex
  flex-direction: column
  box-sizing: border-box
  justify-content: center
  align-items: center
  z-index: 10
  width: 35%
  .messageWrapper
    display: flex
    align-items: center
    flex-direction: column
    z-index: 11
    @include fadeInAnimation(1s)
    margin-bottom: 18px
    .title
      color: var(--blue-2)
      font-size: 48px
      font-weight: 400
      line-height: 57.6px
      margin-bottom: 10px
    .message
      color: var(--blue-2)
      font-size: 18px
      line-height: 22px
      font-weight: 400
      text-align: center
  .form
    width: 90%
  .resend
    margin-top: 28px
    .message
      color: var(--blue-2)
      font-weight: 400
      font-size: 14px
      line-height: 18px
      z-index: 11
    .button
      color: var(--green-1)
      font-weight: 400
      font-size: 14px
      line-height: 18px
      z-index: 11
      cursor: pointer
  .back
    margin-top: 28px
    color: var(--green-1)
    font-weight: 400
    font-size: 14px
    line-height: 18px
    z-index: 11
    cursor: pointer

@include for-phone-only
  .wrapper
    width: 80%
    .messageWrapper
      text-align: center
      .title
        font-size: 32px
        line-height: 36px
        font-weight: 400
      .message
        font-size: 12px
        font-weight: 400
        line-height: 16px
    .form
      width: 100%
      height: 48px
      margin-bottom: 28px
    .resend
      margin-top: 28px
      .message
        color: var(--blue-2)
        font-weight: 400
        font-size: 14px
        line-height: 18px
        z-index: 11
      .button
        color: var(--green-1)
        font-weight: 400
        font-size: 14px
        line-height: 18px
        z-index: 11
        cursor: pointer
    .back
      margin-top: 28px
      color: var(--green-1)
      font-weight: 400
      font-size: 14px
      line-height: 18px
      z-index: 11
      cursor: pointer