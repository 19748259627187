@import '../../styles/animations'

.container
  position: fixed
  top: 65px
  left: 0
  width: 100vw
  height: 100vh
  z-index: 999
  backdrop-filter: blur(3px)
  background-color: rgba(128, 128, 128, 0.379)
  display: flex
  justify-content: center
  .modal
    background: var(--white-1)
    box-shadow: 0px 20px 60px rgba(54, 54, 54, 0.253)
    border-radius: 4px
    min-width: 470px
    min-height: 100px
    height: fit-content
    margin-top: 30px
    padding: 30px 10px
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    @include popUpAnimation(0.5s)
    .icon
      position: absolute
      width: 37px
      height: 37px
      border: 1px solid var(--gray-5)
      background-color: var(--gray-5)
      right: 15px
      top: 10px
      cursor: pointer
      transition: 0.5s
      display: flex
      justify-content: center
      align-items: center
      border-radius: 23px
      &:hover
        @include turnAnimation(0, 180deg)
