.conatiner
  width: 380px
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 10px
  .title, .description
    font-size: 24px
    letter-spacing: -0.8px
    color: var(--blue-2)
    text-align: center
    margin-bottom: 20px
  .title
    font-size: 32px
  .buttons
    height: 130px
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-evenly
