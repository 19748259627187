@import '../../styles/animations'

.wrapper
  width: 100%
  color: var(--blue-2)
  text-align: center
  width: 362px
  .image
    margin-bottom: 30px
    width: 362px
    height: 303px
    @include popUpAnimation(0.5s)
  .title
    font-size: 24px
    font-weight: 700
    padding: 0 20px
    line-height: 28px
    @include fadeInAnimation(1s)
    @include animationDelay(0.5s)
  .message
    margin-top: 8px
    padding: 0 20px
    font-size: 16px
    line-height: 18px
    @include fadeInAnimation(1s)
    @include animationDelay(1s)
