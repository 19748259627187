.wrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 24px
  margin-left: 16px
  padding-bottom: 16px
  .element-1
    width: calc( 75% - 16px )
    height: 22px
    border-radius: 8px
    margin-bottom: 4px
  .element-2, .element-3, .element-4, .element-5, .element-6, .element-7, .element-8, .element-9, .element-10, .element-11, .element-12, .element-13, .element-14, .element-15, .element-16, .element-17, .element-18, .element-19, .element-20, .element-21, .element-22, .element-23, .element-24, .element-25
    width: calc( ( 100% / 2 ) - 16px )
    border-radius: 8px
    height: 24px
  .element-3, .element-5, .element-7, .element-9, .element-11, .element-13, .element-15, .element-17, .element-19, .element-21, .element-23, .element-25
    width: 25%
    margin-right: 16px
  .element-26
    width: 35%
    height: 32px
    border-radius: 8px
    margin: 10px 0 0 30%
