@import '../../../../styles/animations'

.wrapper
  display: flex
  align-items: center
  padding: 12px 16px
  height: 55px
  margin-bottom: 4px
  cursor: pointer
  border-radius: 4px
  transition: 0.3s
  border: 1px solid var(--gray-16)
  &:hover
    border-color: var(--blue-1)
    .arrow
      color: var(--blue-1)
      @include turnAnimation(0, 360deg)
  &.active
    background-color: var(--blue-2)
    .title, .status, .total, .date, .arrow
      color: var(--white-1)
    .left
      .description
        color: var(--white-1)
  .title, .status, .total, .date, .arrow
    color: var(--blue-2)
    font-size: 16px
    text-align: right
  .left
    flex: 1
    display: flex
    height: 100%
    flex-direction: column
    justify-content: space-between
    .title
      text-align: left
      margin: 6px 0 8px 0
      font-weight: 700
    .description
      margin-bottom: 8px
      font-size: 16px
      color: var(--gray-6)
  .right
    margin-right: 20px
    display: flex
    flex-direction: column
    height: 100%
    justify-content: space-between
    .status
      font-size: 12px
      .point
        margin: auto 5px
        width: 6px
        display: inline-block
        height: 6px
        border-radius: 100%
        background: var(--green-1)
        &.deleted
          background: var(--blue-3)
    .date
      font-size: 10px
      text-transform: lowercase
  .arrow
    transition: 0.5s
    font-size: 14px
    margin-right: 4px
