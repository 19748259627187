@import '../../../../styles/animations'

.wrapper
  display: flex
  flex-direction: column
  background-color: var(--blue-2)
  @include fadeInAnimation(0.5s)
  overflow: hidden
  .header
    padding: 16px
    display: flex
    flex-direction: column
    border-bottom: 0.2px solid var(--white-1)
    .inputContainer
      height: 40px
      display: flex
      margin: 0 0 24px 0
      border-radius: 18px
      background-color: var(--white-1)
      .icon
        margin: 12px 8px 12px 16px
      .input
        flex: 1
        font-size: 16px
        line-height: 15px
        margin: 0 16px 0 0
        &::placeholder
          font-size: 16px
          line-height: 15px
          letter-spacing: -0.4px
          color: rgba(54, 54, 54, 0.6)
    .texts
      display: flex
      justify-content: space-between
      .name
        font-weight: bold
      .name, .logout
        font-size: 16px
        line-height: 25px
        color: var(--white-1)
  .resume
    display: flex
    margin: 32px 0
    align-items: center
    color: var(--white-1)
    flex-direction: column
    .title
      font-size: 20px
      line-height: 25px
      margin: 0 0 8px 0
    .value
      font-size: 30px
      margin: 0 0 8px 0
      font-weight: bold
      line-height: 25px
    .yesterday
      opacity: 0.6
      font-size: 20px
      line-height: 25px
  .content
    display: flex
    align-items: center
    flex-direction: column
    border-radius: 18px 18px 0 0
    background-color: var(--white-1)
    .title
      margin: 24px 0
      font-size: 18px
      line-height: 15px
    .actions
      display: flex
      margin-bottom: 32px
      gap: 16px
      .action
        width: 88px
        height: 98px
        display: flex
        align-items: center
        border-radius: 18px
        flex-direction: column
        justify-content: center
        background: var(--white-1)
        border: 1px solid var(--gray-2)
        .circle
          width: 40px
          height: 40px
          display: flex
          margin: 0 0 8px 0
          border-radius: 50%
          align-items: center
          justify-content: center
          background-color: var(--gray-3)
          .icon
            font-size: 24px
        .name
          font-size: 12px
          line-height: 10px
          font-weight: bold
          letter-spacing: -0.4px
    .soldProducts
      width: 100%
      display: flex
      margin: 0 0 24px 0
      flex-direction: column
      .title
        font-size: 24px
        font-weight: bold
        line-height: 14px
        letter-spacing: -1px
        margin: 0 16px 24px 16px
        @include fadeInAnimation(0.5s)
      .productItems
        display: flex
        margin: 0 16px
        row-gap: 16px
        flex-direction: column
        .empty
          @include fromLeftAnimation(1s, 200px)
          color: var(--blue-2)
          opacity: 0.8
          margin-bottom: 15px
