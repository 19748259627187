@import '../../../../styles/animations'

.container
  @include fadeInAnimation(0.5s)
  width: calc( 100% - 32px )
  padding: 16px
  .infoWrapper
    background: var(--white-1)
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
    border-radius: 10px
    width: 100%
    padding: 16px 0
    margin-bottom: 16px
    display: flex
    flex-direction: column
    align-items: center
    .image
      height: 133px
  .list
    width: 210px
    margin: 16px 0
    .item
      font-size: 14px
      display: flex
      justify-content: space-between
      color: var(--black-2)
      .value
        color: var(--gray-14)
        font-weight: bold
  .state
    font-size: 14px
    color: var(--black-2)
    margin-top: 10px
