.wrapper
  width: 460px
  display: flex
  flex-direction: column
  align-items: center
  padding: 10px 0
  .image
    width: 64px
    height: 64px
  .title
    font-weight: bold
    font-size: 28px
    line-height: 32px
    letter-spacing: -0.8px
    color: var(--black-2)
    margin: 16px 0
  .description
    text-align: center
    font-size: 16px
    line-height: 20px
    margin: 0 40px 56px 40px
