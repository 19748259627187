.wrapper
  margin: 44px 0 0 0
  display: flex
  justify-content: center
  column-gap: 24px
  width: 100%
  max-width: 700px
  .button
    width: calc( (100% / 2) - 16px )
    max-width: 346px
    height: 46px
