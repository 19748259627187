.wrapper
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: space-between
  gap: 24px
  width: 100%
  .element-1
    width: calc( (100% * 2/3) - 12px )
    height: 230px
    border-radius: 8px
  .element-2
    width: calc( (100% / 3) - 12px )
    border-radius: 8px
    height: 230px
  .element-3, .element-4, .element-5, .element-6
    width: calc( (100% / 4) - 45px )
    border-radius: 8px
    height: 116px
  .element-7, .element-8, .element-9
    width: calc( (100% / 3) - 16px )
    border-radius: 8px
    height: 254px
