.loader 
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 2em
  height: 2em
  margin: auto
  z-index: 99999
  position: fixed
  overflow: visible

.loader:before 
  top: 0
  left: 0
  content: ""
  width: 100%
  height: 100%
  display: block
  position: fixed
  background-color: rgba(243, 240, 240, 0.3)

.loader:not(:required):after 
  width: 1em
  height: 1em
  content: ""
  display: block
  font-size: 10px
  margin: 1em 0 0 1em
  border-radius: 0.5em
  animation: spinner 1500ms infinite linear
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0


@keyframes spinner 
  0% 
    transform: rotate(0deg)
  
  100% 
    transform: rotate(360deg)
