@import '../../../../styles/animations'
@import '../../../../styles/mixin'

.table
  width: calc(100% - 60px)
  padding: 0 25px
  margin-bottom: 20px
  @include fadeInAnimation(0.5s)
  .header, .body
    width: 100%
    display: grid
    grid-template-columns: 1fr 1fr 3fr repeat(5, 1fr) 0.5fr
    cursor: pointer
    .cell
      height: 40px
      border-bottom: 1px solid var(--gray-5)
      background-color: var(--white-1)
      display: flex
      align-items: center
      text-align: center
  .header
    .cell
      color: var(--blue-2)
      font-weight: bold
      font-size: 12px
      margin: 0 12px 0 -4px
      .title
        margin: 0 4px 0 4px
      .icon
        margin-top: 4px
      &.active
        background-color: var(--green-10)
        .icon
          +svgColor(var(--green))
  .body
    .cell
      font-size: 14px
      transition: 0.3s
      color: var(--gray-6)
      &:nth-child(1)
        word-break: break-all
        text-align: left
      &:nth-child(3)
        overflow: hidden
        word-break: break-all
        text-align: left
        justify-content: flex-start
        margin-right: 12px
    &:hover
      transform: scale(1.001)
      box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.25)
      .cell
        border-color: var(--blue-1)
        &:nth-child(3)
          padding-left: 15px
          text-decoration: underline
          color: var(--blue-1)
.noResults
  font-size: 25px
  margin-top: 80px
.loading
  margin-top: 100px
  font-size: 25px
  color: var(--blue-2)
