@import "src/styles"

.wrapper
  display: flex
  width: 100%
  column-gap: 24px
  margin: 16px 0 16px 0
  flex-direction: column
  justify-content: space-between
  color: var(--yankees-blue)
  +fadeInAnimation(0.3s)
  .balance
    display: flex
    padding: 0px 16px 16px 16px
    margin: 0 16px 16px 0
    flex-direction: column
    align-items: flex-end
    background-color: var(--yankees-blue)
    color: var(--white)
    border: 1px solid var(--yankees-blue)
    border-radius: 4px
    .title
      display: flex
      font-size: 20px
      font-weight: 700
      line-height: 24px
      padding-right: 8px
      margin-top: 16px
    .total
      display: flex
      flex-direction: column
      align-items: flex-end
      .text
        font-size: 16px
        font-weight: 400
        line-height: 20px
        padding-right: 8px
      .value
        font-size: 48px
        font-weight: 700
        line-height: normal
        letter-spacing: -1px
        padding-right: 8px
  .firstRow, .secondRow
    display: flex
    align-items: flex-start
    gap: 16px
    padding-right: 16px
    margin-bottom: 16px