@import '../../../../styles/animations'

.container
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  height: 100%
  overflow: hidden
  @include fadeInAnimation(0.3s)
  .button
    overflow: hidden
    position: fixed
    bottom: 0
    width: calc( 100% - 32px )
    padding: 8px 16px
    background-color: var(--white-1)
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12)
    z-index: 2
