.wrapper
  width: 100%
  height: 40px
  border: 1px solid var(--gray-2)
  border-radius: 10px
  display: flex
  align-items: center
  transition: 1s
  &.active
    border: 1px solid var(--blue-1)
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
    .icon
      color: var(--green-1)
  .icon
    color: var(--black-2)
    font-size: 20px
    margin: 0 10px 0 20px
    transition: 0.3s
  .input
    flex: 1
    border: none
    font-size: 18px
    line-height: 19px
    &::placeholder
      color: var(--gray-12)
      opacity: 0.5
