@import '../../../../styles/animations'

.wrapper
  height: 80px
  display: flex
  border-radius: 18px
  align-items: center
  box-sizing: border-box
  background: var(--white-1)
  border: 1px solid var(--gray-4)
  @include listAnimation(5)
  overflow: hidden
  .figure
    width: 56px
    height: 56px
    display: flex
    margin: 12px 8px
    align-items: center
    justify-content: center
    .img
      max-width: 100%
      max-height: 100%
  .info
    flex: 1
    display: flex
    flex-direction: column
    justify-content: center
    .number
      font-size: 15px
      line-height: 2px
      font-weight: bold
      margin: 0 0 8px 0
      letter-spacing: -0.4px
    .name
      font-size: 12px
      font-weight: bold
      line-height: 16px
      margin: 0 0 8px 0
      letter-spacing: -0.4px
      color: rgba(54, 54, 54, 0.6)
    .units
      display: flex
      font-size: 9px
      line-height: 4px
      color: rgba(54, 54, 54, 0.6)
      .strong
        margin: 0 0 0 8px
        font-weight: bold
  .value
    font-size: 15px
    font-weight: bold
    line-height: 12px
    margin: 0 10px 0 0
    letter-spacing: -0.6px
  .icon
    font-size: 12px
    margin: 0 8px 0 0