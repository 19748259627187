@import '../../../../styles/animations'

.container
  width: 100%
  display: flex
  justify-content: center
  margin: 30px 0
  @include fadeInAnimation(0.5s)
.loading
  margin-top: 100px
  font-size: 25px
  color: var(--blue-2)
