.title
  font-size: 32px
  color: var(--blue-2)
  margin-bottom: 35px
.information
  width: 100%
  display: flex
  justify-content: center
  column-gap: 20px
.loading
  font-size: 32px
  margin-top: 100px
