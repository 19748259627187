\:root
  --font-regular: 'Trustha-Regular', 'Roboto', 'Oxygen', sans-serif
  --font-bold: 'Trustha-Bold', 'Roboto', 'Oxygen', sans-serif
  --font: "Causten Round", "causten", Arial, Helvetica, system-ui, sans-serif

  --white-1: #FFFFFF
  --black-1: #000000
  --black-2: #363636
  --black-3: #222325
  --black-4: #565560
  --gray-1: #5B5B5B
  --gray-2: #AFAFAF
  --gray-3: #F8F8F8
  --gray-4: #EEEEEE
  --gray-5: #EBEBEB
  --gray-6: #666A8F
  --gray-7: #F4F4F4
  --gray-8: #727273
  --gray-9: #AFCEF7
  --gray-10: #D1E3FA
  --gray-11: #F6F9FE
  --gray-12: #2F2F2F
  --gray-13: #DDDDDD
  --gray-14: #979797
  --gray-15: #4A4A4A
  --gray-16: #DDDFE8
  --gray-17: #C6C9D8
  --gray-18: #DDDFE8
  --gray-19: #EBEDF1
  --gray-20: #A2A6BD
  --gray-21: #F9F9FA
  --red-1: #FF0136
  --red-2: #FF3E66
  --red-3: #F56763
  --red-4: #F9E3DE
  --blue-1: #1B73E8
  --blue-2: #2B2845
  --blue-3: #56B0F3
  --green-1: #00D27C
  --green-2: #00d27c40
  --orange-1: #FD9535
  --purple-1: #8A78FB
  --purple-2: #725DF1

  --white: #FFFFFF
  --ghost-white: #FAFBFC

  --extraordinaria: #FFEDD4

  --gray-200: #A2A6BD
  --gray-300: #8084A2
  --gray-100: #C6C9D8
  --gray-60: #DDDFE8
  --gray-25: #EBEDF1
  --gray-5: #F9F9FA
  --dark-blue-gray: #666A8F

  --yankees-blue: #2B2845
  --blue-de-france: #3584E0
  --blue-jeans: #56B0F3
  --sonadora: #66A0D6
  --diferente: #31549E

  --green: #00B56B
  --green-10: #E5F8F0
  --philippine-green: #01834E
  --cercana: #5EBD78
  --productiva: #37723D

  --red-10: #FFE6EB

  --chiper-brand-red: #FF0136
  --transformadora: #A12822

  --positiva: #EF909B
  --creativa: #7255A2

  --bright-yellow: #FF9F26
  --visionaria: #F4AA1C

  --cosmic-latte: #FFF5E9
  
  --transparent-1: rgba(43, 40, 69, 0.4)
  --transparent-2: rgba(255, 255, 255, 0.2)
  --transparent-3: rgba(0, 0, 0, 0.7)
  --transparent-4: rgba(243, 240, 240, 0.275)
