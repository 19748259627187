.wrapper
  display: flex
  flex-direction: column
  justify-content: right
  width: 759px
  padding: 56px 0 0 60px
  .title
    display: inline-block
    margin-bottom: 36px
    font-weight: 700
    font-size: 24px
    line-height: 28px
    color: var(--blue-2)
  .input
    width: 100%
    display: flex
    padding: 16px 0
    border-bottom: 1px solid var(--gray-19)
    column-gap: 19px
    .icon
      padding-top: 10px
      font-size: 28px
  .areaText
    @extend .input
    border-bottom: none
  .submit
    margin-top: 64px
    align-self: flex-end
    width: 344px
