@import '../../styles/animations'

.wrapper
  width: 220px
  height: 100%
  position: fixed
  display: flex
  flex-direction: column
  top: 0
  left: 0
  background-color: var(--blue-2)
  z-index: 1000
  transform: translateX(-100%)
  transition: 0.3s
  .overlay
    position: fixed
    top: 0
    left: 220px
    width: calc( 100vw - 220px )
    height: 100%
    background: rgba(255, 255, 255, 0.22)
    display: none
  &.open
    transform: translateX(0)
    .overlay
      display: block
  .menuTop
    width: 100%
    color: var(--gray-16)
    box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.144)
    margin-bottom: 34px
    display: flex
    flex-direction: column
    .store
      font-size: 24px
      line-height: 28px
      padding: 24px 16px 8px 32px
      min-height: 64px
    .name
      display: flex
      align-items: center
      font-size: 12px
      text-align: center
      padding-left: 40px
      font-weight: 700
      margin: 24px 0
      text-decoration: none
      color: var(--gray-16)
      transition: 0.5s
      &:hover
        color: var(--green-1)
      .status
        display: flex
        width: 9px
        height: 9px
        background-color: var(--green-1)
        border-radius: 100%
        margin: 0 10px 3px 0
  .items
    display: flex
    flex-direction: column
    padding-left: 40px
    height: 100%
    overflow-x: hidden
    .item
      display: flex
      cursor: pointer
      transition: 0.3s
      color: var(--gray-16)
      text-decoration: none
      margin: 14px 0
      &:hover
        color: var(--green-1)
        padding-left: 10px
        .icon
          color: var(--green-1)
      &.active
        color: var(--green-1)
        .icon
          color: var(--green-1)
      .icon
        font-size: 20px
        width: 20px
        margin-right: 20px
        color: var(--gray-16)
      .name
        font-size: 18px
        font-weight: 400
        letter-spacing: -0.8px
  .bottom
    bottom: 0
    background: var(--blue-2)
    width: 100%
    .logo
      display: block
      margin: 16px auto
      width: 67px
      transition: 0.5s
      &:hover
        transform: scale(1.1)
    .logoutWrapper
      display: flex
      align-items: center
      width: 100%
      height: 73px
      padding: 17px 29px 17px 29px
      box-sizing: border-box
      box-shadow: 4px 0px 4px rgba(255, 255, 255, 0.2)
      cursor: pointer
      .door
        color: var(--gray-16)
        font-size: 22px
        margin: 4px 13px 0 0
        transition: 0.3s
      .closeSection
        margin-top: 6px
        color: var(--gray-16)
        letter-spacing: -0.1px
        transition: 0.3s
        font-size: 18px
      &:hover
        .door, .closeSection
          color: var(--orange-1)
