.wrapper
  color: var(--blue-2)
  .debtsInfo
    display: flex
    box-sizing: border-box
    padding: 0 12px
    height: 78px
    align-items: center
    justify-content: space-between
    margin-bottom: 16px
    .debts
      font-weight: 400
      font-size: 16px
      line-height: 20px
    .total
      display: flex
      align-items: center
      .sign
        font-weight: 400
        font-size: 16px
        line-height: 20px
      .number
        font-weight: 400
        font-size: 28px
        line-height: 32px
        letter-spacing: -1px