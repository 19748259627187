@import '../../../../styles/animations'

.table
  width: calc(100% - 60px)
  padding: 0 25px
  margin-bottom: 20px
  @include fadeInAnimation(0.5s)
  .header, .body
    width: 100%
    display: grid
    grid-template-columns: repeat(5, 1fr)
    cursor: pointer
    .cell
      height: 40px
      border-bottom: 1px solid var(--gray-5)
      background-color: var(--white-1)
      display: flex
      align-items: center
      justify-content: center
      &:first-child
        justify-content: flex-start
      &:last-child
        justify-content: flex-end
  .header
    .cell
      color: var(--blue-2)
      font-weight: bold
      font-size: 12px
  .body
    .cell
      font-size: 14px
      transition: 0.3s
      color: var(--gray-6)
    &:hover
      transform: scale(1.001)
      box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.25)
      .cell
        border-color: var(--blue-1)
        &:nth-child(1)
          padding-left: 15px
          text-decoration: underline
          color: var(--blue-1)
  .icon
    margin: 0 0 5px 5px
    font-size: 16px
.noSales
  margin-top: 60px
  font-size: 25px
  color: var(--gray-6)
