@import '../../styles/animations'

.container
  width: 100%
  position: relative
  border-radius: 8px
  display: flex
  justify-content: center
  box-sizing: border-box
  border: 1px solid var(--gray-2)
  &.disabled
    background-color: var(--gray-3)
    border-color: var(--gray-5)
  &:focus-within
    border: 1px solid var(--blue-2)
  &.error
    border: 1px solid var(--red-1)
    .icon
      color: var(--red-1)
    .errorMessage
      opacity: 1
      @include fromRightTextAnimation(0.5s)
  .label
    font-size: 10px
    position: absolute
    left: 10px
    top: 5px
    line-height: 14px
    color: var(--gray-2)
    @include fromRightTextAnimation(0.3s)
    & + .select
      padding: 10px 0 0 10px
  .select
    width: 100%
    height: 48px
    padding-left: 10px
    border: none
    background: transparent
    font-size: 15px
    line-height: 20px
    color: var(--black-2)
    outline: 0
    appearance: none
    transition: 0.3s
    cursor: pointer
  .medium
    height: 40px
    & +.icon
      top: 15px
  .icon
    font-size: 13px
    position: absolute
    right: 15px
    top: 20px
  .errorMessage
    opacity: 0
    font-size: 10px
    line-height: 12px
    position: absolute
    color: var(--red-1)
    top: calc(100% + 2px)
    left: 10px
    transition: opacity 0.3s
