.wrapper
  margin-top: 100px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .image
    margin: 20px 0
  .text
    font-size: 30px
    color: var(--blue-2)
    text-align: center
  .button
    margin: 30px 0
