@import '../../styles/breakpoints'

.container
  width: 100%
  margin-top: 16px
  padding-bottom: 20px

+for-phone-only
  .container
    width: calc( 100% - 32px )
    margin: 0 auto
