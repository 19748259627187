.wrapper
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  background-color: var(--gray-3)
  padding: 12px 24px 15px 24px
  .title
    font-size: 16px
    line-height: 20px
    color: var(--blue-2)
    font-weight: 700
    margin-bottom: 8px
  .total
    font-size: 28px
    line-height: 34px
    margin-bottom: 2px
    font-weight: 400
  .boxes
    width: 100%
    margin-top: 8px
    display: flex
    justify-content: space-between
    .box
      width: calc((100% / 2) - 30px)
      padding: 6px 8px 6px 8px
      background-color: var(--white-1)
      box-shadow: 0px 6px 18px rgba(54, 54, 54, 0.08)
      border-radius: 4px
      font-size: 16px
      color: var(--blue-2)
      text-align: center
      .value
        font-weight: bold
        margin-left: 5px
