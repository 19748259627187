.wrapper 
  width: 100%
  height: 40px
  display: flex
  font-family: Avenir
  align-items: center
  background: var(--white-1)
  justify-content: space-around
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15)
  .texts 
    .title, .subTitle 
      display: flex
      line-height: 12px
      align-items: flex-end
      justify-content: center
      .label, .percentage 
        display: flex 
        margin: 0 4px 0 0
        align-items: flex-end
      .value, .text 
        display: flex
        align-items: flex-end
    .title
      margin: 0 0 4px 0
      .label 
        font-weight: 300
        font-size: 12.5px
      .value 
        font-weight: 700
        font-size: 16.5px
    .subTitle 
      .percentage 
        font-size: 10px
        font-weight: 700
        margin: 0 4px 0 0
        color: rgb(151, 151, 151)
      .text 
        font-size: 10px
        font-weight: 300
        color: rgb(151, 151, 151)
      
