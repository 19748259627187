.container
  width: calc(100% - 40px)
  height: 400px
  overflow-y: scroll
  border-bottom: 1px solid var(--black-1)
  .table
    width: 100%
    padding: 0 30px
    margin-top: 30px
    .head, .cell
      height: 40px
      font-size: 12px
      text-align: left
      color: var(--blue-2)
      border-bottom: 1px solid var(--gray-5)
      background-color: var(--white-1)
      &:last-child
        text-align: right
    .cell
      font-size: 16px
      line-height: 20px
      color: var(--gray-6)
