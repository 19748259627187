@import "src/styles"

.wrapper
  width: calc( 100% - 16px )
  overflow-x: hidden
  @include fadeInAnimation(0.8s)
  @include animationDelay(500ms)
  .button
    width: calc( 100% - 16px )
    display: flex
    justify-content: center
    margin: 0 16px 12px 16px
    @include fadeInAnimation(0.8s)
    @include animationDelay(500ms)