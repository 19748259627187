.wrapper
  background: var(--white)
  border: 1px solid var(--gray-60)
  border-radius: 8px
  box-sizing: border-box
  padding: 24px
  display: flex
  flex-direction: column
  height: fit-content

