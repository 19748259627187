@import '../../styles/animations'
@import '../../styles/breakpoints'

.wrapper
  width: 100%
  color: var(--blue-2)
  text-align: center
  width: 381px
  transform: translateY(-60px)
  overflow: hidden
  .image
    margin-bottom: 30px
    width: 381px
    height: 318px
    @include fromTopAnimation(0.5s, 50px)
  .title
    @include fromLeftAnimation(0.5s, 100px)
    @include animationDelay(0.5s)
    font-size: 24px
    font-weight: 700
    line-height: 28px
  .message
    @include fromRightAnimation(0.5s, 100px)
    @include animationDelay(0.5s)
    margin-top: 8px
    font-size: 16px
    line-height: 20px

@include for-phone-only
  .wrapper
    .image
      margin: 9px 0 18px 0
      width: 306.72px
      height: 256px