.imageWrapper
  margin-top: 35px
  width: 150px
  height: 150px
  border-radius: 100%
  background-color: var(--gray-6)
  border: 2px solid var(--white-1)
  box-shadow: 0px 0px 30px rgba(21, 35, 95, 0.507)
  position: relative
  .icon
    position: absolute
    background-color: var(--blue-1)
    color: var(--white-1)
    padding: 8px
    right: 5px
    border-radius: 100%
    font-size: 18px
    cursor: pointer
    transition: 0.3s
    &:hover
      background: var(--green-1)
      transform: scale(1.2)
  .image
    border-radius: 100%
    height: 100%
    object-fit: cover
    width: 100%
.inputs
  display: flex
  flex-direction: column
  align-items: center
  overflow-x: hidden
  .inputsWrapper
    margin-top: 35px
    width: 600px
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    row-gap: 15px
    .input
      width: calc( (100% / 2 ) - 10px )
  .button
    margin: 20px 0 50px 0
    width: calc( (100% / 2 ) - 10px )
