.wrapper 
  display: flex
  margin: 0 0 20px 0
  align-items: center
  flex-direction: column
  padding: 20px 0 20px 0
  justify-content: center
  .datePickersButtons 
    gap: 10px
    display: flex
    margin: 0 0 20px 0
  .pickers
    display: flex
    column-gap: 8px
  .tabs 
    height: 30px
    display: flex
    cursor: pointer
    width: fit-content
    border-radius: 30px
    background-color: var(--gray-7)
    .tab 
      height: 100%
      width: 194px
      display: flex
      font-size: 18px
      font-weight: 700
      align-items: center
      justify-content: center
      transition: none
      &.active 
        border-radius: 30px
        color: var(--blue-1)
        box-sizing: border-box
        border: 2px solid var(--blue-1)
        background-color: var(--white-1)
      
