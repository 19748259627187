.wrapper
  width: calc( 100% - 80px )
  display: flex
  justify-content: center
  margin-top: 8px
  .left, .right
    width: calc( 100% / 2 )
    display: flex
    flex-direction: column
    color: var(--blue-2)
    font-size: 16px
    height: 83px
    justify-content: space-around
    .value
      font-weight: 700
      margin-left: 16px
  .left
    padding-right: 90px
    margin-left: -42px
    .label
      &:first-child
        .value
          text-transform: lowercase
  .right
    padding-left: 40px
    border-left: 1px solid var(--gray-18)
