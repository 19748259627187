.wrapper
  width: 411px
  border: 1px solid var(--gray-17)
  padding-bottom: 12px
  .subTitle
    font-weight: 700
    font-size: 16px
    color: var(--gray-6)
    line-height: 26px
  .body
    padding: 20px 20px 14px
    .products
      @extend .subTitle
      width: 100%
      display: inline-block
      border-bottom: 1px solid var(--gray-17)
      margin-bottom: 16px
    .card
      display: flex
      justify-content: space-between
      padding-bottom: 8px
      font-size: 16px
      .left
        display: flex
        flex-direction: column
      .right
        align-self: flex-end
    .resume
      display: block
      padding-bottom: 16px
      border-bottom: 1px solid var(--gray-17)
      .info
        display: flex
        justify-content: space-between
        font-size: 16px
        line-height: 26px
        color: var(--blue-2)
    .total
      display: flex
      justify-content: space-between
      padding-top: 8px
      border-bottom: 1px solid var(--gray-17)
      margin-bottom: 16px
  .tail
    width: 100%
    height: 15px
    border-top: 1px solid var(--gray-17)
