.card
  height: 436px
  width: 272px
  border-radius: 10px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  padding: 19px
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25)
  .imageWrapper
    width: 274px
    height: 312px
    background: var(--gray-3)
    border-radius: 10px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around
    position: relative
    .image
      max-height: 200px
      max-width: 200px
    .title
      font-size: 25px
      margin: 0 10px
      line-height: 30px
      text-align: center
      color: var(--blue-2)
    .addImageWrapper
      position: absolute
      top: 100px
      right: 0
      cursor: pointer
      &:hover
        .icon
          background: var(--green-1)
          transform: scale(1.2)
    .icon
      background-color: var(--blue-1)
      color: var(--white-1)
      padding: 5px
      border-radius: 100%
      font-size: 18px
      transition: 0.3s
    .inputFile
      width: 0
      opacity: 0
      position: absolute
      left: 0
      top: -5px 
      background: red
      cursor: pointer
      padding: 5px 0  5px 30px
  .input
    width: 274px
