.wrapper
  width: 572px
  height: 482px
  margin-left: 20px
  overflow: hidden
  .inputs
    margin: 110px 0 70px 0
    row-gap: 10px
  .inputs, .buttons
    width: 100%
    display: flex
    flex-wrap: wrap
    column-gap: 10px
    justify-content: center
    .input, .button
      width: 263px
