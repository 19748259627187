.wrapper
  width: 100%
  display: flex
  padding: 20px
  flex-direction: column
  background-color: var(--white-1)
  border-radius: 16px
  .title
    font-size: 20px
    font-weight: 700
    line-height: 27.32px
  .table
    display: flex
    flex-direction: column
    .titles
      display: grid
      margin: 0 0 10px 0
      grid-template-columns: auto 100px 140px
      margin-bottom: 2px
      .units, .values
        opacity: 0.4
        text-align: end
        font-size: 12px
        color: var(--blue-2)
        font-weight: 500
        line-height: 10px
    .items
      display: grid
      grid-template-columns: 40px auto 100px 140px
      .number, .name, .units, .value
        font-size: 18px
        font-weight: 700
        line-height: 28px
        height: 35px
        overflow: hidden
      .number, .units, .value
        color: var(--gray-15)
      .name
        font-weight: 400
        color: var(--gray-1)
      .units, .value
        text-align: end
        font-weight: 700
