@import "src/styles"

.wrapper
  display: flex
  width: 100%
  flex-direction: column
  column-gap: 24px
  justify-content: space-between
  color: var(--yankees-blue)
  +fadeInAnimation(0.3s)
  .title
    font-size: 18px
    font-weight: 700
    line-height: 22px
    letter-spacing: -0.1px
  .date
    display: flex
    padding: 16px 16px 16px 0
    flex-direction: column
    justify-content: center
    align-items: center