@import 'src/styles'
    
.button
  margin: 24px 0
  padding-right: 16px
  display: flex
  justify-content: center
  align-items: center
  height: 40px
  width: 100%
  @include fadeInAnimation(0.3s)
  @include animationDelay(500ms)