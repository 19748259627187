@import '../../styles/animations'

.container
  position: relative
  z-index: 100
  height: 72px
  background-color: var(--gray-7)
  .wrapper
    @include fromTopAnimation(0.5s, 60px)
    @include animationDelay(1s)
    position: fixed
    top: 0
    height: 72px
    width: calc( 100% - 80px )
    background-color: var(--white-1)
    display: flex
    align-items: center
    padding: 0 40px
    justify-content: space-between
    overflow: hidden
    .menu
      display: flex
      align-items: center
      cursor: pointer
      color: var(--blue-2)
      .icon
        font-size: 20px
        margin-right: 12px
      .title
        font-size: 20px
        font-weight: 700
        margin-top: 2px
      &:hover
        .title
          text-decoration: underline
    .logo
      width: 79px
      cursor: pointer
      transition: 0.5s
      &:hover
        transform: scale(1.1)
