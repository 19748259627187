.button
  width: 48px
  height: 48px
  background-color: var(--white-1)
  border: none
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.377)
  border-radius: 10px
  cursor: pointer
  transition: 0.3s
  .icon
    font-size: 20px
    color: var(--blue-1)
  &:hover
    box-shadow: 0px 2px 14px rgba(27, 116, 232, 0.425)
    transform: scale(1.1)
