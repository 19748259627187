.wrapper
  width: 386px
  height: 438px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  .textWrapper
    display: flex
    flex-direction: column
    align-items: center
    .title
      font-weight: bold
      font-size: 40px
      line-height: 44px
      letter-spacing: -1.8px
      color: var(--black-2)
    .code
      font-size: 16px
      color: var(--black-2)
      margin-top: 30px
      .numberCode
        font-weight: bold
  .inputs
    display: flex
    width: 100%
    flex-direction: column
    row-gap: 15px
