.container
  margin-top: 58px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .formDescription, .title, .description, .noChecked
    font-size: 16px
    line-height: 20px
    color: var(--gray-6)
    margin: 5px 0
    transition: 0.5s
  .permissions
    margin-top: 20px
    width: calc(100% - 150px)
    display: flex
    flex-wrap: wrap
    justify-content: center
    .permission
      width: calc((100vw - 200px) / 2)
      display: flex
      justify-content: space-around
      align-items: center
      margin: 10px 0
      .information
        flex: 0.7
      .title, .noChecked
        font-weight: bold
        color: var(--blue-2)
      .noChecked
        text-decoration: underline
