.wrapper
  display: flex
  flex-direction: column
  align-items: center
  .title
    font-weight: bold
    font-size: 28px
    color: var(--black-2)
  .description
    margin: 15px 0
    color: var(--black-2)
    font-size: 16px
  .formatFile
    font-size: 14px
    color: var(--black-2)
  .buttons
    margin-top: 20px
    display: flex
    column-gap: 10px
  .inputWrapper
    position: relative
    .input
      cursor: pointer
      position: absolute
      top: -10px
      opacity: 0
      height: 58px
      width: 100%
  .reading
    position: fixed
    top: 60vh
    .readingText
      font-weight: bold
      font-size: 28px
      color: var(--gray-1)
      text-align: center
