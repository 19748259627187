@import '../../../../styles/animations'
@import '../../../../styles/breakpoints'

.container
  @include fadeInAnimation(0.5s)
  margin-top: 24px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding-bottom: 20px
  overflow: hidden
  .top
    width: 100%
  .inputsWrapper
    margin-top: 30px
    width: calc(100% - 20px)
    display: flex
    justify-content: space-around
    .input
      width: calc((100% - 50px) / 4)
      max-width: 350px
  .button
    display: none

+for-phone-only
  .container
    .top
      display: none
    .inputsWrapper
      flex-direction: column
      row-gap: 15px
      width: 100%
      .input
        min-width: 100%
    .button
      display: block
      position: fixed
      bottom: 0
      left: 0
      width: calc( 100% - 32px )
      padding: 8px 16px
      background-color: var(--white-1)
      box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.12)
