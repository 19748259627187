.wrapper
  width: 100%
  padding-bottom: 65px
  overflow-x: hidden
  .item
    padding: 0 16px
    height: 70px
    border: 1px solid var(--gray-19)
    border-radius: 4px
    margin-bottom: 8px
    display: flex
    align-items: center
    justify-content: space-between
    .left
      height: 100%
      display: flex
      justify-content: center
      flex-direction: column
      .name, .date
        letter-spacing: -0.4px
        color: var(--blue-2)
        margin: 3px 0
      .date
        color: var(--gray-6)
    .right
      letter-spacing: -0.4px
      font-weight: bold
      color: var(--gray-6)
      .arrow
        font-size: 12px
        padding: 15px 0 15px 15px
  .pagination
    height: 50px
    display: flex
    border-bottom: 0.2px solid var(--gray-2)
    transition: 0.3s
    .arrow
      margin: auto
      transform: rotate(90deg)
    &:active
      padding: 15px 0
      .arrow
        color: var(--blue-1)
.noData
  display: flex
  justify-content: center
  z-index: 1
  overflow: hidden
  margin-top: 16px
