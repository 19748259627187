@import '../../styles/breakpoints'

.wrapper
  position: fixed
  top: -100px
  opacity: 0
  height: 0
  width: 100%
  transition: 1s
  z-index: 99999999
  background: red
  left: 0
  right: 0
  &.show
    top: 24px
    opacity: 1
  .toast
    transition: 1s
    margin: 0 auto
    box-shadow: 0px 8px 16px #9591ba14
+for-phone-only
  .wrapper
    .toast
      width: calc( 100% - 32px )
