@import '../../../../styles/animations'

.container
  @include fadeInAnimation(0.5s)
  .title
    color: var(--blue-2)
    font-weight: 700
    font-size: 24px
    margin: 16px 0
  .button
    position: fixed
    bottom: 16px
    left: 16px
    width: calc( 100% - 32px )
