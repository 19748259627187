.modal
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  width: calc( 100% / 2 )
  .title
    color: var(--blue-2)
    font-size: 28px
    font-weight: 700
  .inputWrapper
    width: 100%
    display: flex
    border-bottom: 1px solid var(--green-1)
    margin: 16px 0
    height: 48px
    justify-content: center
    align-items: flex-end
    transition: 0.3s
    &.error
      border-color: var(--red-1)
    .input
      width: 100%
      text-align: center
      height: fit-content
      font-size: 24px
      color: var(--blue-2)
