.fourDigitInput
  display: flex
  justify-content: space-between
  width: 100%
  .digitInput
    width: 60px
    height: 60px
    text-align: center
    font-size: 16px
    border-bottom: 1px solid var(--gray-60)
    margin-right: 10px
    &:last-child 
      margin-right: 0
    &:focus
      outline: none
      border-bottom: 1px solid
    &.error
      border-bottom: 1px solid var(--chiper-brand-red)
.errorMessage
  display: flex
  justify-content: center
  margin-top: 16px
  font-size: 14px
  font-weight: 800