$base: '../assets/fonts'

@font-face
  font-family: 'icomoon'
  src: url('#{$base}/icomoon.eot') format('embedded-opentype'), url('#{$base}/icomoon.ttf') format('truetype'), url('#{$base}/icomoon.woff') format('woff'), url('#{$base}/icomoon.svg') format('svg')
  font-style: normal
  font-weight: normal
  font-display: block

[class^="icon-"], [class*=" icon-"]
  font-family: 'icomoon' !important
  speak: never
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-lock:before
  content: "\e901"

.icon-person:before
  content: "\e902"

.icon-offer:before
  content: "\e903"

.icon-tag:before
  content: "\e904"

.icon-search:before
  content: "\e905"

.icon-arrow:before
  content: "\e906"

.icon-dropdown-arrow:before
  content: "\e907"

.icon-check:before
  content: "\e908"

.icon-close:before
  content: "\e909"

.icon-bulletin:before
  content: "\e90a"

.icon-burger:before
  content: "\e90b"

.icon-clients:before
  content: "\e90c"

.icon-closures:before
  content: "\e90d"

.icon-inventory:before
  content: "\e90e"

.icon-orders:before
  content: "\e90f"

.icon-providers:before
  content: "\e910"

.icon-logout:before
  content: "\e912"

.icon-edit:before
  content: "\e913"

.icon-add:before
  content: "\e914"

.icon-cancel:before
  content: "\e915"

.icon-save:before
  content: "\e916"

.icon-delete:before
  content: "\e917"

.icon-download:before
  content: "\e918"

.icon-sort:before
  content: "\e919"

.icon-calendar:before
  content: "\e91a"

.icon-pencil:before
  content: "\e920"

.icon-upload:before
  content: "\e900"

.icon-lens:before
  content: "\e91b"

.icon-slash:before
  content: "\e91c"

.icon-padlock:before
  content: "\e91d"

.icon-invoice:before
  content: "\e91e"

.icon-store:before
  content: "\e939"

.icon-time:before
  content: "\e91f"

.icon-desktop:before
  content: "\e921"

.icon-filter:before
  content: "\e911"

.icon-notepad:before
  content: "\e922"

.icon-reports:before
  content: "\e923"

.icon-calendar-2:before
  content: "\e924"

.icon-store:before
  content: "\e939"

.icon-burguer-2:before
  content: "\e925"

.icon-close-2:before
  content: "\e927"

.icon-arrow-2:before
  content: "\e928"

.icon-search-2:before
  content: "\e929"

.icon-logout-2:before
  content: "\e92a"

.icon-reports-2:before
  content: "\e926"

.icon-package:before
  content: "\e92b"

.icon-key:before
  content: "\e92c"

.icon-truck:before
  content: "\e92d"

.icon-money-cash:before
  content: "\e92e"

.icon-customer:before
  content: "\e92f"

.icon-bill:before
  content: "\e930"

.icon-store-v2:before
  content: "\e931"

.icon-location:before
  content: "\e932"

.icon-phone:before
  content: "\e933"

.icon-dni:before
  content: "\e934"

.icon-message:before
  content: "\e935"

.icon-light-plus:before
  content: "\e936"

.icon-light-minus:before
  content: "\e937"