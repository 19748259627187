@import '../../../../styles/animations'

.container
  width: 100%
  margin-top: 16px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  @include fadeInAnimation(0.5s)
  