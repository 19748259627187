@import '../../styles/breakpoints'

.wrapper
  margin-top: 50px
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .image
    margin: 30px 0
  .text
    font-size: 30px
    color: var(--blue-2)
    text-align: center

+for-phone-only
  .wrapper
    margin-top: calc( 100% / 2 )
    .image
      margin: 10px 0
    .text
      font-size: 20px
