@import './styles/variables'
@import './styles/fonts'
@import './styles/icons'

*
  margin: 0
  padding: 0
  border: 0
  border-style: solid
  object-fit: contain
  text-decoration: none
  font-family: var(--font)
  -webkit-tap-highlight-color: transparent
  transition: 0.3s
  background-color: transparent

button:not(:disabled)
  cursor: pointer
  
ul, ol
  list-style: none

html
  height: -webkit-fill-available
  scroll-behavior: smooth

body, #root
  height: 100%
  height: stretch
  height: -moz-available
  height: -webkit-fill-available
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  scrollbar-color: var(--gray-17) var(--white-1)
  scrollbar-width: auto

input
  border: none
  outline: none

*::-webkit-scrollbar-track
  background-color: var(--gray-5) !important
*::-webkit-scrollbar
  width: 5px !important
*::-webkit-scrollbar-thumb
  border-radius: 50px !important
  background-color: var(--gray-60) !important

