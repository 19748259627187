.switch
  position: relative
  display: flex
  width: 30px
  height: 15px
.input
  opacity: 0
  width: 0
  height: 0
.slider
  position: absolute
  cursor: pointer
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: var(--gray-2)
  transition: 0.4s
  &:before
    position: absolute
    bottom: 0
    left: 0.5px
    content: ""
    height: 15px
    width: 15px
    background-color: #FFFFFF
    transition: 0.4s
  .input:checked + &
    background-color: var(--green-1)
  .input:checked + &:before
    transform: translateX(14px)
  &.round
    border-radius: 20px
  &.round:before
    border-radius: 50%
