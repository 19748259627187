@import '../../styles/animations'

.wrapper
  width: 100%
  height: 48px
  display: flex
  position: relative
  border-radius: 4px
  align-items: center
  box-sizing: border-box
  border: 1px solid var(--gray-16)
  padding: 8px 16px 8px 16px
  &.disabled
    background-color: var(--gray-3)
    border-color: var(--gray-5)
  &:focus-within
    border: 1px solid var(--blue-2)
  &.error
    border: 1px solid var(--red-1)
    .errorMessage
      opacity: 1
      @include fromRightTextAnimation(0.5s)
  &.help
    .helperText
      opacity: 1
      @include fromRightTextAnimation(0.5s)
  .left
    flex: 1
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    .label
      font-size: 10px
      line-height: 14px
      color: var(--gray-6)
      @include fromRightTextAnimation(0.3s)
    .input
      font-size: 16px
      line-height: 20px
      color: var(--black-2)
      background-color: transparent
  .errorMessage, .helperText
    opacity: 0
    font-size: 10px
    line-height: 12px
    position: absolute
    transition: opacity .3s
  .errorMessage
    top: calc(100% + 2px)
    left: 10px
    color: var(--red-1)
  .helperText
    left: 0
    top: calc(100% + 4px)
    color: var(--gray-300)