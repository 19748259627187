@import '../../../../styles/animations'

.container
  @include fadeInAnimation(0.5s)
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  .link
    text-decoration: none
.loading
  display: flex
  justify-content: center
  margin: 150px
  font-size: 25px
  color: var(--gray-6)
