.container
  width: 100%
  margin-top: 15px
  display: flex
  flex-direction: column
  align-items: center
  .link
    color: var(--blue-1)
    font-size: 20px
    position: absolute
    left: 10px
    top: 80px
    display: flex
    align-items: center
    text-decoration: none
    .icon
      transform: rotate(180deg)
      font-size: 12px
      margin: 0 10px
      transition: 0.5s
    &:hover
      text-shadow: 0px 0px 25px rgba(15, 49, 160, 0.692)
      .icon
        animation: toLeft 1s infinite
        text-shadow: none

@keyframes toLeft
  70%
    transform: rotate(180deg) translateX(10px)
  100%
    transform: rotate(180deg) translateX(0px)
