@import '../../../../styles/animations'

.wrapper
  @include fadeInAnimation(0.3s)
  height: 62px
  display: flex
  justify-content: space-between
  align-items: center
  border-radius: 4px
  color: var(--blue-2)
  font-size: 16px
  padding: 0 16px
  width: calc( 100% - 48px )
  margin: 4px 0
  cursor: pointer
  border: 1px solid transparent
  transition: .3s
  &:hover
    border-color: var(--blue-1)
  &:active
    border-color: var(--blue-2)
    background-color: var(--blue-2)
    .name, .total
      color: var(--white-1)
  .index
    background-color: var(--gray-19)
    border-radius: 100%
    width: 32px
    height: 32px
    display: flex
    align-items: center
    justify-content: center
  .name
    flex: 1
    padding: 0 16px
    .subtitle
      font-size: 12px
