@import '../../../../styles/animations'

.wrapper
  width: 472px
  height: 40px
  background-color: var(--gray-5)
  border-radius: 18px
  display: flex
  justify-content: space-between
  align-items: center
  .input
    flex: 1
    font-size: 16px
    background-color: transparent
    padding-left: 20px
  &.noResults
    background-color: var(--red-4)
  .icon
    width: 40px
    height: 40px
    background-color: var(--blue-2)
    color: var(--white-1)
    font-size: 20px
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)
    border-radius: 18px
    display: flex
    justify-content: center
    align-items: center
    @include fadeInAnimation(0.3s)
  .closeIcon
    font-size: 13px
    padding-right: 20px
    @include fadeInAnimation(0.3s)
    cursor: pointer
