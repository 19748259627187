@import '../../styles/animations'

.wrapper
  width: 100%
  display: flex
  align-items: center
  transition: 0.5s
  .arrow
    display: flex
    cursor: pointer
    font-size: 18px
    animation: fromLeft 1s ease
    transform: rotate(180deg)
    margin-right: 17px
  .inputWrapper
    flex: 1
    height: 46px
    border: 1px solid var(--gray-16)
    border-radius: 4px
    display: flex
    align-items: center
    transition: 0.5s
    &:focus-within
      border-color: var(--blue-2)
    &.error
      border: 1px solid var(--red-1)
    .icon
      color: var(--blue-2)
      font-size: 16px
      margin: 0 12px 0 18px
      transition: 0.3s
    .input
      flex: 0.99
      border: none
      transition: 0.5s
      outline: none
      font-size: 16px
      height: calc( 100% - 2px )
      color: var(--blue-2)
      &::placeholder
        transform: translateY(2px)
        font-size: 16px
        color: var(--gray-20)
    .iconClose
      font-size: 12px
      padding: 10px
      margin-right: 8px
      border-radius: 100%
      color: var(--gray-6)
      cursor: pointer
      transition: 0.5s
      @include stepAndTurnAnimation(1s, 1.4)
      &:hover
        background-color: var(--green-2)



@keyframes fromLeft
  from
    opacity: 0
    transform: translateX(-50px) rotate(180deg)
  to
    opacity: 1
