%Regular-H1
  font-weight: 700
  font-size: 32px
  line-height: 36px
  letter-spacing: -0.1px

%Bold-H1
  @extend %Regular-H1
  font-weight: 700

%Regular-H3
  font-weight: 700
  font-size: 24px
  line-height: 28px
  letter-spacing: -0.1px

%Bold-H3
  @extend %Regular-H3
  font-weight: 700

%Regular-H4
  font-weight: 400
  font-size: 20px
  line-height: 24px
  letter-spacing: -0.1px

%Bold-H4
  @extend %Regular-H4
  font-weight: 700

%Regular-Body
  font-weight: 400
  font-size: 16px
  line-height: 20px

%Bold-Body
  @extend %Regular-Body
  font-weight: 700

%Regular-Medium-Body
  font-weight: 400
  font-size: 14px
  line-height: 18px

%Regular-Label
  font-weight: 400
  font-size: 12px
  line-height: 16px

%Bold-Label
  @extend %Regular-Label
  font-weight: 700

%Regular-Subhead
  font-weight: 400
  font-size: 10px
  line-height: 14px

%Bold-Subhead
  @extend %Regular-Subhead
  font-weight: 700

%Regular-Caption-1
  font-weight: 400
  font-size: 18px
  line-height: 22px
  letter-spacing: -0.4px

%Bold-Caption-1
  @extend %Regular-Caption-1
  font-weight: 700

%Regular-Subtitle
  font-weight: 400
  font-size: 18px
  line-height: 22px
  letter-spacing: -0.1px

%Bold-Subtitle
  @extend %Regular-Subtitle
  font-weight: 700

%Small-2
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 20px
