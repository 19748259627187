.wrapper
  width: calc(100% - 90px)
  height: 74px
  background-color: var(--white-1)
  box-shadow: 0px 5px 18px rgba(54, 54, 54, 0.137)
  border-radius: 10px
  display: flex
  justify-content: space-between
  align-items: center
  margin: 15px 0 24px 0
  padding: 0 20px
  .item
    display: flex
    flex-direction: column
    font-size: 12px
    line-height: 16px
    color: var(--gray-6)
    .value
      font-size: 16px
      line-height: 24px
    .red
      color: var(--red-3)