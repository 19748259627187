.container
  width: calc(100% - 60px)
  padding: 0 30px
  height: 85px
  display: flex
  justify-content: space-between
  align-items: flex-end
  &.shadow
    box-shadow: 0px 6px 18px rgba(54, 54, 54, 0.08)
  .information
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-end
    .routeLink, .actualRoute, .title
      font-size: 18px
      letter-spacing: -0.4px
      color: var(--blue-2)
    .routeLink
      color: var(--gray-6)
      text-decoration: none
      &:hover
        text-decoration: underline
        cursor: pointer
    .title
      margin: 10px 0 5px 0
      font-weight: bold
      font-size: 32px
    .icon
      font-size: 12px
      color: var(--gray-6)
      margin: 0 6px 0 8px
  .buttons
    column-gap: 8px
    height: 100%
    display: flex
    align-items: flex-end
    margin-bottom: 14px
