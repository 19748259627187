@mixin fromRightTextAnimation($duration)
  @keyframes fromRightText
    from
      opacity: 0
      transform: translateX(100px)
    to
      opacity: 1
  animation: fromRightText $duration ease-in

@mixin popUpAnimation($duration)
  @keyframes pupUp
    from
      transform: scale(0)
      opacity: 0
    to
      opacity: 1
  animation: pupUp $duration ease-out

@mixin fadeInAnimation($duration)
  @keyframes fadeIn
    from
      opacity: 0
    to
      opacity: 1
  animation: fadeIn $duration ease-in-out

@mixin dropDownAnimation($duration, $height)
  @keyframes dropDown
    from
      height: 0
      overflow: hidden
    to
      overflow: hidden
      height: $height
  animation: dropDown $duration ease-in-out

@mixin turnAnimation($from, $to)
  @keyframes turn
    from
      transform: scale(1.001) rotate($from)
    to
      transform: scale(1.001) rotate($to)
  animation: turn 0.7s ease

@mixin stepAnimation()
  @keyframes step
    0%
      opacity: 0
      transform: scale(0)
    50%
      box-shadow: 0px -3px 19px -6px rgba(0, 0, 0, 0.557)
      transform: scale(1.2)
  animation: step 0.7s ease-in


@mixin fromLeftAnimation($duration, $translate)
  @keyframes fromLeft
    from
      opacity: 0
      transform: translateX(-$translate)
    to
      opacity: 1
  animation: fromLeft $duration ease-out

@mixin fromRightAnimation($duration, $translate)
  @keyframes fromRight
    from
      opacity: 0
      transform: translateX($translate)
    to
      opacity: 1
  animation: fromRight $duration ease-out

@mixin fromTopAnimation($duration, $translate)
  @keyframes fromtop
    from
      opacity: 0
      transform: translateY(-$translate)
    to
      opacity: 1
  animation: fromtop $duration ease-out

@mixin PopUpStepAnimation($duration)
  @keyframes popUpStep
    0%
      transform: scale(0)
      opacity: 0
    30%
      transform: scale(0.5)
    60%
      transform: scale(1.3)
    100%
      opacity: 1
  animation: popUpStep $duration ease

@mixin fromBottomAnimation($duration, $translate)
  @keyframes frombottom
    from
      opacity: 0
      transform: translateY(+$translate)
    to
      opacity: 1
  animation: frombottom $duration ease-out

@mixin listAnimation($total)
  animation: fromRight 500ms ease both
  @for $i from 1 through $total
    &:nth-child(#{$i})
      animation-delay: $i * 80ms
  @keyframes fromRight
    from
      opacity: 0
      transform: translateX(300px)
    to
      opacity: 1

@mixin animationDelay($time)
  animation-delay: $time
  animation-fill-mode: both

@mixin menuFromLeftToRight()
  @keyframes menuLeftToRight
    0%
      width: 0
    100%
      width: 100%
  animation: menuLeftToRight 1.5s ease-out forwards
      
@mixin stepAndTurnAnimation($duration, $scale)
  @keyframes step
    0%
      opacity: 0
      transform: scale(0) rotate(360deg)
      background-color: var(--green-1)
    60%
      transform: scale($scale)
  animation: step $duration ease
  
@mixin skeletonAnimation()
  animation: pulse 0.8s linear infinite
  @keyframes pulse
    0%
      opacity: 1
    50%
      opacity: 0.5
    100%
      opacity: 1
