.wrapper
  text-align: center
  font-size: 16px
  .date
    display: inline-block
    padding-bottom: 16px
    text-transform: capitalize
    color: var(--blue-2)
  .message
    max-width: calc(100% - 40px)
    padding: 0 20px
    min-height: 42px
    letter-spacing: -1px
    color: var(--blue-2)
    word-wrap: break-word
    &.focus
      font-weight: 700
      color: var(--green-1)
  .copy
    width: 100%
    display: flex
    flex-direction: column
    padding: 16px 0
    font-size: 12px
    font-weight: 300
    color: var(--blue-2)
