@import '../../../../styles/animations'
@import '../../../../styles/breakpoints'

.wrapper
  display: flex
  flex-direction: column
  box-sizing: border-box
  justify-content: center
  align-items: center
  z-index: 10
  .logoWrapper
    margin-bottom: 16px
    margin-top: 65px
    display: flex
    justify-content: center
    z-index: 11
    @include fadeInAnimation(1s)
    .logo
      width: 121px
      height: 62.4px
  .messageWrapper
    display: flex
    align-items: center
    flex-direction: column
    z-index: 11
    @include fadeInAnimation(1s)
    .title
      color: var(--blue-2)
      font-size: 48px
      font-weight: 400
      line-height: 57.6px
      margin-bottom: 10px
    .message
      color: var(--blue-2)
      font-size: 18px
      line-height: 22px
      font-weight: 400
      width: 233px
  .inputWrapper
    margin-top: 28px
    width: 30%
    z-index: 11
    @include fadeInAnimation(1s)
    .phone
      margin-bottom: 28px
      background-color: var(--white-1)
    .password
      background-color: var(--white-1)
      margin-bottom: 28px
  .passwordRecuperation
    margin-top: 28px
    color: var(--green-1)
    font-weight: 400
    font-size: 14px
    line-height: 18px
    z-index: 10
    cursor: pointer
  .images
    z-index: 5
    .computer
      position: fixed
      bottom: 0
      right: 0
      height: calc( 100% / 1.9 )
      @include fromBottomAnimation(1s, 220px)
      z-index: 5
    .rightDots
      position: fixed
      top: 103px
      bottom: 0
      right: 13px
      width: 188px
      height: 251px
      z-index: 4
    .elipse
      position: fixed
      left: 0
      right: 0
      top: 0
      bottom: 0
      width: 100%
      height: 100%
      z-index: 3
      opacity: 50%
    .leftDots
      position: fixed
      top: 7px
      left: 10%
      width: 201px
      height: 257px
      z-index: 4

@include for-phone-only
  .wrapper
    .logoWrapper
      .logo
        margin-bottom: 48px
    .messageWrapper
      width: calc( 100% - 50px)
      text-align: center
      .title
        font-size: 32px
        line-height: 36px
        font-weight: 400
      .message
        font-size: 12px
        font-weight: 400
        line-height: 16px
    .inputWrapper
      width: 80%
      height: 48px
    .passwordRecuperation
      margin-top: 190px
      color: var(--green-1)
      font-weight: 400
      font-size: 14px
      line-height: 18px
      z-index: 10
      cursor: pointer
    .images
      .computer
        display: none
      .rightDots
        display: none
      .leftDots
        position: absolute
        left: 0
        top: auto
        bottom: 0

.loaderWrapper
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  .loader
    height: 500px
