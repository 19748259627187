.wrapper
  height: 48px
  border: none
  display: flex
  cursor: pointer
  padding: 0 20px
  position: relative
  border-radius: 8px
  align-items: center
  box-sizing: border-box
  justify-content: center
  transition: 0.3s
  background-color: var(--blue-2)
  -webkit-tap-highlight-color: transparent
  &:hover
    box-shadow: 0px 1px 15px #2b284567
    .text
      text-decoration: underline
  &:disabled
    cursor: default
    background: var(--gray-5)
    box-shadow: none
    .icon, .text
      color: var(--gray-2)
  &.fullWidth
    width: 100%
  &.outline
    background-color: var(--white-1)
    border: 1px solid var(--blue-2)
    .icon, .text
      color: var(--blue-2)
    &:disabled
      background-color: var(--gray-3)
      .icon, .text
        color: var(--gray-2)
  .icon, .text
    font-size: 16px
    font-weight: 700
    color: var(--white-1)
  .leftIcon
    margin: 0 8px 0 0
  .rightIcon
    margin: 0 0 0 8px
