@import "src/styles"

.wrapper
  display: flex
  width: 100%
  flex-direction: column
  column-gap: 24px
  justify-content: space-between
  color: var(--yankees-blue)
  +fadeInAnimation(0.3s)
  .title
    display: flex
    align-items: center
    font-size: 20px
    font-weight: 700
    line-height: 24px
    margin: 24px 0 16px 0
  .list
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px
    padding-right: 16px
    .text, .value, .empty
      font-size: 18px
      font-weight: 400
      line-height: 22px
    .empty
      color: var(--dark-blue-gray)
      margin: auto
      