@import 'src/styles'

.wrapper
  .title
    display: flex
    font-size: 20px
    font-weight: 700
    line-height: 24px
    margin: 24px 0 16px 0
  .responsable
    font-size: 18px
    font-weight: 400
    line-height: 22px