@import '../../../../styles/breakpoints'

.wrapper
  display: flex
  height: 32px
  background-color: var(--gray-5)
  border-radius: 16px
  margin: 20px 0
  -webkit-tap-highlight-color: transparent
  .switch
    width: 115px
    border-radius: 16px
    font-weight: bold
    background-color: var(--gray-5)
    color: var(--blue-2)
    border: none
    cursor: pointer
    transition: 0.3s
  .active
    background-color: var(--blue-2)
    color: var(--white-1)
    cursor: auto

+for-phone-only
  .wrapper
    height: 40px
    border-radius: 38px
    .switch
      border-radius: 38px
      width: 74px
