@import "src/styles"

.container
  +fadeInAnimation(0.3s)
  display: flex
  row-gap: 24px
  flex-direction: column
  overflow-y: auto
  height: calc( 100vh - 140px )
  padding: 0 40px 24px 0
  width: 100%
  .empty
    width: 450px
    text-align: center
    @extend %Regular-H4
    margin: 120px auto
    color: var(--dark-blue-gray)
    line-height: 30px