@import '../../../../styles/animations'

.container
  width: 100%
  @include fadeInAnimation(0.5s)
  .inputs
    margin-top: 24px
    width: 100%
    display: flex
    justify-content: space-around
    .inputWrapper
      margin-top: 16px
      width: calc((100% - 50px) / 5)
      max-width: 350px
