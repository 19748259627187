.wrapper
  width: calc( 100% - 50px )
  display: flex
  align-items: center
  justify-content: space-between
  height: 40px
  padding: 0 25px
  margin: 30px 0
  .resume
    width: 182px
    height: 100%
    border-radius: 9px
    border: solid 1px var(--gray-2)
    padding-left: 17px
    display: flex
    flex-direction: column
    justify-content: center
    color: var(--blue-2)
    .resumeTitle
      font-size: 13px
    .resumeTotal
      font-size: 20px
      line-height: 18px
      font-weight: bold
  .searchBar
    flex: 0.9
