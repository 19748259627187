@import '../../styles/animations'
@import '../../styles/typography'
@import '../../styles/variables'

.container
  width: 100%
  display: flex
  flex-direction: column
  position: relative
  z-index: 13
  .wrapper
    z-index: 13
    height: 50px
    display: flex
    flex-direction: column
    justify-content: center
    border-bottom: 1px solid var(--gray-60)
    overflow: hidden
    transition: 0.3s
    margin-left: 100px
    &:focus-within
      border-color: var(--yankees-blue)
      .label
        display: block
      .input
        &::placeholder
          color: transparent
    &.error
      border-color: var(--chiper-brand-red)
    &.disabled
      background-color: var(--gray-25)
      .label
        color: var(--gray-200)
      .input
        color: var(--gray-200)
        &::placeholder
          color: var(--gray-300)
    .label
      display: none
      @include fadeInAnimation(0.3s)
      @extend %Regular-Subhead
      color: var(--dark-blue-gray)
      &.visible
        display: block
    .input
      @extend %Regular-Body
      background-color: transparent
      margin-left: 10px
      &::placeholder
        color: var(--gray-200)
      &.isPhone
        margin-left: 80px
  .countryCode
    position: absolute
    z-index: 15
    height: 68%
    margin: 16px 16px 0 16px
    border-bottom: 1px solid var(--gray-60)
    .country
      display: flex
      align-items: center
      .img
        width: 18px
        height: 18px
        margin-right: 4px
      .text
        flex: 1
        font-size: 16px
        line-height: 20px
        font-weight: bold
        color: var(--black-2)
      .arrow
        width: 16px
        height: 16px
        margin-left: 4px
        &.up
          transform: rotate(180deg)
  .errorMessage, .count
    @extend %Regular-Label
    margin: 4px 16px 0 16px
    @include fadeInAnimation(0.3s)
  .errorMessage
    color: var(--chiper-brand-red)
  .count
    @extend %Bold-Label
    color: var(--gray-200)
    align-self: flex-end
  .items
    +PopUpStepAnimation(0.3s)
    position: absolute
    top: 52px
    left: 12px
    min-width: 52px
    max-width: 85px
    max-height: 156px
    overflow-y: auto
    background-color: var(--white)
    z-index: 13
    filter: drop-shadow(0px 2px 16px rgba(43, 40, 69, 0.06))
    border-radius: 4px
    padding: 4px 0
    cursor: default
    .item
      display: flex
      height: 30px
      align-items: center
      padding: 0 10px
      column-gap: 10px
      .name
        @extend %Regular-Body
        font-size: 16px
      .flag
        height: 16px
        width: 16px
