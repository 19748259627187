@import '../../styles/animations'

.wrapper
  height: 64px
  animation: fromTop 0.5s ease-in
  .fixedWrapper
    position: fixed
    width: 100%
    height: 64px
    display: flex
    align-items: center
    background-color: var(--white-1)
    z-index: 99
    .content
      display: flex
      align-items: center
      .arrow
        margin: 0 5px 0 15px
        color: var(--blue-2)
        font-size: 16px
        transform: rotate(180deg)
        border-radius: 100%
        padding: 10px
        transition: transform 0.3s
      .name
        font-weight: bold
        font-size: 18px
        line-height: 18px
        color: var(--blue-2)
      &:active
        .arrow
          color: var(--blue-1)
          background-color: var(--gray-4)
          transform: rotate(180deg) translateX(10px)

@keyframes fromTop
  from
    opacity: 0
    transform: translateY(-64px)
