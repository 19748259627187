.wrapper
  width: 100%
  margin-top: 30px
  padding-top: 16px
  border-top: 1px solid var(--gray-19)
  color: var(--blue-2)
  .title
    font-weight: 700
    font-size: 20px
    margin: 16px 0 0 20px
  .list
    height: calc( 100vh - 500px )
    overflow-y: scroll
    padding-right: 10px
    width: calc( 100% + 5px )
    .row
      padding-left: 20px
      display: grid
      grid-template-columns: 20px 1fr auto
      column-gap: 16px
      margin-top: 16px
      .name
        padding-left: 10px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
      &.devolution
        color: var(--gray-6)
        .name 
          text-decoration: line-through
