@import "src/styles"

.wrapper
  display: flex
  width: 100%
  column-gap: 24px
  justify-content: space-between
  color: var(--yankees-blue)
  +fadeInAnimation(0.3s)
  .box
    width: calc( 100% / 3 )
    height: 100%
    row-gap: 16px
    .title
      @extend %Bold-Subtitle
    .items
      display: flex
      row-gap: 16px
      flex-direction: column
      height: 100%
      .item
        @extend %Regular-Body
        display: flex
        justify-content: space-between
      .empty
        color: var(--dark-blue-gray)
        margin: auto
