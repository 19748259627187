@import '../../styles/animations'

.wrapper
  width: calc(312px - 48px)
  padding: 16px 24px
  border-radius: 8px
  background-color: var(--white-1)
  box-shadow: 0px 6px 10px rgba(128, 98, 96, 0.16)
  position: relative
  @include fadeInAnimation(0.5s)
  .header
    display: flex
    align-items: center
    justify-content: space-between
    color: var(--blue-2)
    .arrow
      cursor: pointer
      font-size: 10px
      width: 28px
      height: 28px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 100%
      &:first-child
        transform: rotate(180deg)
      &:hover
        background-color: var(--green-2)
    .disabledArrow
      font-size: 10px
      width: 28px
      height: 28px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 100%
      cursor: default
      color: var(--gray-2)
      background-color: 0
      &:first-child
        transform: rotate(180deg)
    .text
      display: flex
      cursor: pointer
      align-items: center
      justify-content: center
      .icon
        font-size: 10px
      .month
        font-weight: 700
        text-transform: capitalize
      .year
        font-weight: 700
        margin: 0 6px
  .daysSelector
    padding: 16px 0 0 0
    display: grid
    row-gap: 8px
    grid-template-columns: repeat(7, 1fr)
    color: var(--blue-2)
    .cell
      display: flex
      align-items: center
      justify-content: center
      &:nth-child(7n+1)
        border-radius: 25px 0 0 25px
      &:nth-child(7n+7)
        border-radius: 0 25px 25px 0
      &.today
        .day
          color: var(--green-1)
      &.active
        .day
          color: var(--white-1)
          background-color: var(--green-1)
          &:hover
            background-color: var(--green-1)
      &.range
        background-color: var(--green-2)
      &.dayName
        font-size: 10px
        font-weight: 700
      &.borderLeft
        background: linear-gradient(to right, var(--white-1) 0%, var(--white-1) 50%, var(--green-2) 50%, var(--green-2) 50%)
      &.borderRight
        background: linear-gradient(to left, var(--white-1) 0%, var(--white-1) 50%, var(--green-2) 50%, var(--green-2) 50%)
      &.isDisabled
        .day
          cursor: default
          color: var(--gray-2)
          &:hover
            background-color: transparent
      .day
        width: 28px
        height: 28px
        display: flex
        cursor: pointer
        border-radius: 50%
        align-items: center
        justify-content: center
        color: var(--blue-2)
        &:hover
          background-color: var(--green-2)
        &.disabled
          cursor: default
          color: var(--gray-16)
          &:hover
            background-color: var(--white-1)
  .yearsSelector
    top: 50px
    width: calc( 100% - 32px )
    overflow-y: scroll
    overflow-x: hidden
    position: absolute
    border-radius: 0 0 8px 8px
    height: calc(48px * 4)
    background-color: var(--white-1)
    box-shadow: 0px 6px 10px rgba(128, 98, 96, 0.16)
    @include dropDownAnimation(0.3s,  calc(48px * 4))
    .year
      width: 100%
      height: 48px
      display: flex
      cursor: pointer
      justify-content: center
      align-items: center
      color: var(--blue-2)
      &:hover
        background-color: var(--green-1)
        color: var(--white-1)
  .buttons
    width: 100%
    border-top: 1px solid var(--gray-16)
    margin-top: 16px
    padding-top: 16px
    display: flex
    justify-content: flex-end
    .button
      padding: 8px 16px
      background-color: var(--green-1)
      border: 0
      color: var(--white-1)
      font-weight: 700
      font-size: 16px
      border-radius: 8px
      cursor: pointer
      &:first-child
        background-color: var(--white-1)
        color: var(--green-1)
      &:hover
        text-decoration: underline
      &:disabled
        background-color: var(--gray-16)
        color: var(--blue-2)
        &:hover
          text-decoration: none
