.wrapper
  box-sizing: border-box
  padding: 8px 16px 8px 16px
  color: var(--blue-2)
  .container
    display: flex
    justify-content: space-between
    background-color: var(--white-1)
    margin-bottom: 6px
    .left
      display: flex
      flex-direction: column
      .title
        font-weight: 400
        font-size: 16px
        line-height: 20px
        margin-bottom: 4px
      .value
        font-weight: 700
        font-size: 16px
        line-height: 20px
        margin-bottom: 12px
    .edition
      display: flex
      text-decoration: underline
      font-size: 12px
      color: var(--gray-6)